import React from 'react'
import { Breadcrumbs, styled } from '@material-ui/core'
import { useHistory } from 'react-router'

const BreadcrumbItem = styled('div')(() => ({
  cursor: 'pointer',
  color: (props) => (props.active ? 'black' : 'auto'),
}))

const CustomBreadcrumbs = ({ items }) => {
  const history = useHistory()

  return (
    <Breadcrumbs>
      {items.map((item, index) => (
        <BreadcrumbItem
          key={item.path}
          active={index === items.length - 1}
          onClick={() => history.push(item.path)}
        >
          {item.label}
        </BreadcrumbItem>
      ))}
    </Breadcrumbs>
  )
}

export default CustomBreadcrumbs
