import React from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import Orders from './component/orders'
import Callback from './component/Callback.js'
import ProtectedRoute from './common/ProtectedRoute.js'
import Home from './component/home'
import OrderDetails from './component/orderDetails'
import OrderItemDetails from './component/orderItemDetails'
import Entitlements from './component/entitlements'
import EntitlementDetails from './component/entitlementDetails'

const Routes = () => (
  <Router>
    <Switch>
      <ProtectedRoute exact path="/" component={Home} />
      <ProtectedRoute exact path="/orders" component={Orders} />
      {/* /entitlements is protected in the component file, maybe swithc to this pattern */}
      <Route
        exact
        path="/entitlements"
        render={(routeProps) => <Entitlements {...routeProps} />}
      />
      <Route
        exact
        path="/entitlements/:entitlementId"
        render={(routeProps) => <EntitlementDetails {...routeProps} />}
      />
      <ProtectedRoute
        exact
        path="/orders/:orderSfid"
        component={OrderDetails}
      />
      <ProtectedRoute
        exact
        path="/orders/:orderSfid/order-items/:orderItemSfid"
        component={OrderItemDetails}
      />
      <Route exact path="/logout">
        <Redirect to="/" />
      </Route>
      <ProtectedRoute exact path="/callback" component={Callback} />
      <Route exact path="/**">
        <Redirect to="/" />
      </Route>
    </Switch>
  </Router>
)

export default React.memo(Routes)
