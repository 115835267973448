import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { Button } from '@material-ui/core'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { formatDate } from '../../utils/formatDate.js'
import { HeaderTableCell } from '../containers.jsx'
import { useDispatch } from 'react-redux'
import { OPEN_DIALOG } from '../../redux/dialogReducer.jsx'
import { CLOSE_ALERT } from '../../redux/alertReducer.jsx'
import useOrders from '../../hooks/useOrders.js'
import DelegateDialog from '../dialog/delegateDialog/index.jsx'

export default function OrderTable({ title, initialPageSize }) {
  //ユーザ委任をするOrder
  const [isUserLoading, setIsUserLoading] = useState(true)

  const {
    orders,
    onChangePage,
    onChangeRowsPerPage,
    isLoading,
    pageSize,
    count,
    page,
    setQuery,
  } = useOrders({
    initialPageSize: initialPageSize || 10,
  })

  const dispatch = useDispatch()

  const columns = [
    {
      name: 'orderNumber',
      label: 'Order Number',
      options: {
        customHeadRender: () => (
          <HeaderTableCell style={{ width: '25%' }} key="order number">
            Order Number
          </HeaderTableCell>
        ),
        customBodyRenderLite: (dataIndex) => (
          <Link
            key={orders[dataIndex].orderSfid}
            component={RouterLink}
            to={`/orders/${orders[dataIndex].orderSfid}`}
          >
            {orders[dataIndex].orderNumber}
          </Link>
        ),
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'orderDate',
      label: 'Order Date',
      options: {
        customHeadRender: () => (
          <HeaderTableCell style={{ width: '20%' }} key="order date">
            Order Date
          </HeaderTableCell>
        ),
        filter: false,
        sortThirdClickReset: true,
        customBodyRenderLite: (dataIndex) =>
          formatDate(orders[dataIndex].orderDate),
      },
    },
    {
      name: 'details',
      label: 'details',
      options: {
        customHeadRender: () => (
          <HeaderTableCell style={{ width: '20%' }} key="details">
            Details
          </HeaderTableCell>
        ),
        customBodyRenderLite: (dataIndex) => (
          <div>
            {orders[dataIndex].orderItems
              .filter((product, index) => index < 2)
              .map((product) => (
                <div key={product.productName}>{product.productDetail}</div>
              ))}
            {orders[dataIndex].orderItems.length > 2 && <div>...</div>}
          </div>
        ),
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'owner',
      label: 'owner',
      options: {
        customHeadRender: () => (
          <HeaderTableCell style={{ width: '25%' }} key="owner">
            Owner
          </HeaderTableCell>
        ),
        customBodyRenderLite: (dataIndex) => (
          <div key={orders[dataIndex].orderSfid}>
            {orders[dataIndex].ownerEmail}
          </div>
        ),
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: '',
      label: '',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
        customHeadRender: () => (
          <HeaderTableCell
            style={{ width: '10%' }}
            key="action"
          ></HeaderTableCell>
        ),
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Button
              key={orders[dataIndex].orderSfid}
              variant="contained"
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                dispatch({
                  type: OPEN_DIALOG,
                  component: DelegateDialog,
                  props: {
                    isUserLoading,
                    setIsUserLoading,
                    delegatedOrder: orders[dataIndex],
                  },
                })
                dispatch({
                  type: CLOSE_ALERT,
                })
                setIsUserLoading(true)
              }}
            >
              Delegate
            </Button>
          )
        },
      },
    },
  ]

  const options = {
    onSearchChange: (searchText) => {
      setQuery(searchText || '')
    },
    serverSide: true,
    elevation: 4,
    download: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: pageSize,
    page,
    print: false,
    filter: false,
    viewColumns: false,
    onChangePage,
    onChangeRowsPerPage,
    count,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <Loader type="ThreeDots" color="grey" />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
  }

  return (
    <MUIDataTable
      key="Orders"
      title={title || 'Orders'}
      data={orders}
      columns={columns}
      options={options}
    />
  )
}
