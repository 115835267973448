import React from 'react'
import { PageContent, NoFooterContainer } from './containers.jsx'
import Footer from './footer/Footer'
import Header from './header/Header'
import Loader from 'react-loader-spinner'
import { styled, Typography } from '@material-ui/core'
import { isIE11 } from '../index.js'

const Container = styled('div')({
  width: '100%',
  height: '1000px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const LoadingScreen = () => {
  return (
    <PageContent>
      <NoFooterContainer>
        <Header />
        <Container>
          {isIE11 ? (
            <Typography variant="h5">Loading, please wait...</Typography>
          ) : (
            <Loader type="ThreeDots" color="grey" />
          )}
        </Container>
      </NoFooterContainer>
      <Footer />
    </PageContent>
  )
}

export default LoadingScreen
