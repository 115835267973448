import React from 'react'
import {  Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { OPEN_DIALOG } from '../redux/dialogReducer'
import { CLOSE_ALERT } from '../redux/alertReducer'
import AssignAdminToAcsContainerHubEntitlementDialog from './dialog/assignAdminToAcsContainerHubEntitlement'

const AssignAdminToAcsContainerHubEntitlementButton = ({
  targetEntitlement,
  setNeedsFetchData,
}) => {
  const dispatch = useDispatch()

  return <Button
      variant="contained"
      size="small"
      color="primary"
      onClick={() => {
        dispatch({
          type: OPEN_DIALOG,
          component: AssignAdminToAcsContainerHubEntitlementDialog,
          props: {
            targetEntitlement,
            setNeedsFetchData,
          },
        })
        dispatch({
          type: CLOSE_ALERT,
        })
      }}
    >
      Assign admin to Entitlement
    </Button>
  
}

export default AssignAdminToAcsContainerHubEntitlementButton
