import React from 'react'
import MUIDataTable from 'mui-datatables'
import Loader from 'react-loader-spinner'
import { HeaderTableCell } from '../containers.jsx'
import { formatDate } from '../../utils'
import AssignUserToDojoEntitlementButton from '../AssignUserToDojoEntitlementButton.jsx'
import UnassignButton from '../UnassignButton.jsx'
import Link from '@material-ui/core/Link'
import formatStorageQuota from '../../utils/formatStorageQuota.js'

function getEntitlementType(entitlements) {
  if (entitlements && entitlements.length && entitlements.length > 0) {
    return entitlements[0].application
  } else {
    return 'Unknown'
  }
}

export const storageQuotaColumn = (entitlements) => ({
  name: 'storageQuota',
  label: 'Storage Quota',
  options: {
    customHeadRender: () => <HeaderTableCell>Storage Quota</HeaderTableCell>,
    customBodyRenderLite: (dataIndex) => {
      return formatStorageQuota(entitlements[dataIndex].storageQuota)
    },
    filter: false,
    sortThirdClickReset: true,
  },
})

export const organizationKeyColumn = {
  name: 'organizationKey',
  label: 'Organization Key',
  options: {
    customHeadRender: () => <HeaderTableCell>Organization Key</HeaderTableCell>,
    filter: false,
    sortThirdClickReset: true,
  },
}

export const entitlementIdColumn = (entitlements) => ({
  name: 'entitlementId',
  label: 'Entitlement Id',
  options: {
    customHeadRender: () => <HeaderTableCell>Entitlement Id</HeaderTableCell>,
    customBodyRenderLite: (dataIndex) => {
      const id = entitlements[dataIndex].entitlementId

      return <Link href={`/entitlements/${id}`}>{id}</Link>
    },
    filter: false,
    sortThirdClickReset: true,
  },
})

export default function EntitlementTable({
  title,
  entitlements,
  entitlementsByProductCode,
  isEntitlementLoading,
  setNeedsFetchData,
}) {
  const entitlementType = getEntitlementType(entitlements)

  const applicationColumn = {
    name: 'application',
    label: 'Application',
    options: {
      customHeadRender: () => <HeaderTableCell>Application</HeaderTableCell>,
      sortThirdClickReset: true,
    },
  }

  const assignedEmailColumn = {
    name: 'assignedEmail',
    label: 'Assigned Email',
    options: {
      customHeadRender: () => <HeaderTableCell>Assigned Email</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
    },
  }

  const vmNumberColumn = {
    name: 'vmNumber',
    label: 'VM Number',
    options: {
      customHeadRender: () => <HeaderTableCell>VM Number</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
    },
  }

  const startDateColumn = {
    name: 'startDate',
    label: 'Start Date',
    options: {
      customHeadRender: () => <HeaderTableCell>Start Date</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
      customBodyRenderLite: (dataIndex) => {
        if (entitlements[dataIndex].startDate) {
          return formatDate(entitlements[dataIndex].startDate)
        }
      },
    },
  }

  const expiryDateColumn = {
    name: 'expiryDate',
    label: 'Expiry Date',
    options: {
      customHeadRender: () => <HeaderTableCell>Expiry Date</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
      customBodyRenderLite: (dataIndex) => {
        if (entitlements[dataIndex].expiryDate) {
          return formatDate(entitlements[dataIndex].expiryDate)
        }
      },
    },
  }

  const assignStatusColumn = {
    name: 'assignStatus',
    label: 'Status',
    options: {
      customHeadRender: () => <HeaderTableCell>Status</HeaderTableCell>,
      filter: true,
      sortThirdClickReset: true,
    },
  }

  const modeColumn = {
    name: 'mode',
    label: 'Mode',
    options: {
      customHeadRender: () => <HeaderTableCell>Mode</HeaderTableCell>,
      filter: true,
      sortThirdClickReset: true,
    },
  }

  const assignButtonColumn = {
    name: '',
    label: '',
    options: {
      setCellProps: () => ({
        align: 'right',
      }),
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        switch (entitlements[dataIndex].assignStatus) {
          case 'Unassigned':
            return (
              <AssignUserToDojoEntitlementButton
                targetEntitlement={entitlements[dataIndex]}
                entitlementsOfOneProduct={entitlementsByProductCode}
                setNeedsFetchData={setNeedsFetchData}
              />
            )
          case 'Pending':
          case 'Assigned':
            return (
              <UnassignButton
                targetEntitlement={entitlements[dataIndex]}
                setNeedsFetchData={setNeedsFetchData}
              />
            )
          case 'In Progress':
          case 'Completed':
          case 'Expired':
          case 'Deactivated':
            return
          default:
            return
        }
      },
    },
  }

  // Columns displayed for each entitlement type
  const columns = {
    OnlineTraining: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      assignedEmailColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
      assignButtonColumn,
    ],
    TECloud: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      vmNumberColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
      modeColumn,
    ],
    SmartInsight: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      vmNumberColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
      modeColumn,
    ],
    ContainerHub: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      storageQuotaColumn(entitlements),
      organizationKeyColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
    ],
    Unknown: [],
  }[entitlementType]

  const options = {
    filter: false,
    viewColumns: false,
    print: false,
    download: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 10,
    elevation: 0,
    rowsPerPageOptions: [5, 10, 15],
    textLabels: {
      body: {
        noMatch: isEntitlementLoading ? (
          <Loader type="ThreeDots" color="grey" />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
  }

  return (
    <MUIDataTable
      title={title || 'Entitlements'}
      data={entitlements}
      columns={columns}
      options={options}
    />
  )
}
