import { useMediaQuery, useTheme } from '@material-ui/core'
import { useEffect, useState } from 'react'

const useResponsive = () => {
  const theme = useTheme()
  const moreThanLG = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  })
  const moreThanMD = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  })
  const moreThanSM = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  const [device, setDevice] = useState('lg')

  useEffect(() => {
    setDevice(
      moreThanSM ? (moreThanMD ? (moreThanLG ? 'lg' : 'md') : 'sm') : 'sm'
    )
  }, [moreThanLG, moreThanMD, moreThanSM])

  return device
}

export default useResponsive
