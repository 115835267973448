import uniqBy from 'lodash/uniqBy'

export default function (tableContent, orderItems, application) {
  if (tableContent && orderItems && application) {
    return uniqBy(
      orderItems.filter((orderItem) => {
        return (
          tableContent &&
          tableContent[orderItem.productCode] &&
          ((tableContent[orderItem.productCode].active &&
            tableContent[orderItem.productCode].active[0] &&
            tableContent[orderItem.productCode].active[0].application ===
              application) ||
            (tableContent[orderItem.productCode].history &&
              tableContent[orderItem.productCode].history[0] &&
              tableContent[orderItem.productCode].history[0].application ===
                application))
        )
      }),
      (orderItem) => orderItem.productCode
    )
  } else return
}
