import React from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import {
  PageContent,
  NoFooterContainer,
  BreadcrumbsContainer,
  CenterContainer,
} from '../containers.jsx'
import CustomBreadcrumbs from '../Breadcrumbs/CustomBreadcrumbs'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { styled } from '@material-ui/core'
import { useParams } from 'react-router'
import useEntitlement from '../../hooks/useEntitlement'
import EntitlementDetailsCard from './EntitlementDetailsCard'
import EntitlementUsersTable from './EntitlementUsersTable'
import EntitlementAdminsTable from './EntitlementAdminsTable'
import Box from '@material-ui/core/Box'
import AssignUserToAcsContainerHubEntitlementButton from '../AssignUserToAcsContainerHubEntitlementButton.jsx'
import AssignUserToDojoEntitlementButton from '../AssignUserToDojoEntitlementButton.jsx'
import isEntitlementAcsContainerHub from '../../utils/isEntitlementAcsContainerHub.js'
import isEntitlementDojo from '../../utils/isEntitlementDojo.js'
import AssignAdminToAcsContainerHubEntitlementButton from '../AssignAdminToAcsContainerHubEntitlementButton.jsx'
import getEntitlementsByProductCode from '../../utils/getEntitlementsByProductCode'
import useEntitlements from '../../hooks/useEntitlements.js'
import UnassignButton from '../UnassignButton.jsx'

const VerticalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
})

const EntitlementDetails = () => {
  const { entitlementId } = useParams()
  const { entitlement, setNeedsFetchData, isLoading } = useEntitlement({
    entitlementId,
  })
  const { entitlements } = useEntitlements()

  const admins =
    entitlement && entitlement.assignedUsers
      ? entitlement.assignedUsers.filter((user) => user.isAdmin)
      : []
  const users =
    entitlement && entitlement.assignedUsers
      ? entitlement.assignedUsers.filter((user) => !user.isAdmin)
      : []

  return (
    <PageContent>
      <NoFooterContainer>
        <Header />
        <CenterContainer>
          <BreadcrumbsContainer>
            <CustomBreadcrumbs
              items={[
                { label: 'Home', path: '/' },
                {
                  label: 'Entitlements',
                  path: `/entitlements`,
                },
                {
                  label: entitlementId,
                  path: `/entitlements/${entitlementId}`,
                },
              ]}
            />
            <VerticalContainer>
              {entitlement && (
                <EntitlementDetailsCard entitlement={entitlement} />
              )}
              {entitlement && isEntitlementAcsContainerHub(entitlement) && (
                <Box
                  sx={{
                    marginTop: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <AssignAdminToAcsContainerHubEntitlementButton
                    targetEntitlement={entitlement}
                    setNeedsFetchData={setNeedsFetchData}
                  />
                  <EntitlementAdminsTable
                    entitlementId={entitlement.entitlementId}
                    admins={admins}
                    isLoading={isLoading}
                    setNeedsFetchData={setNeedsFetchData}
                  />
                </Box>
              )}
              {entitlement && (
                <Box
                  sx={{
                    marginTop: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  {isEntitlementDojo(entitlement) ? (
                    entitlement.assignStatus === 'Unassigned' ? (
                      <AssignUserToDojoEntitlementButton
                        targetEntitlement={entitlement}
                        entitlementsOfOneProduct={getEntitlementsByProductCode(
                          entitlements,
                          entitlement.productCode
                        )}
                        setNeedsFetchData={setNeedsFetchData}
                      />
                    ) : entitlement.assignStatus === 'Assigned' ? (
                      <UnassignButton
                        targetEntitlement={entitlement}
                        setNeedsFetchData={setNeedsFetchData}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    isEntitlementAcsContainerHub(entitlement) && (
                      <AssignUserToAcsContainerHubEntitlementButton
                        targetEntitlement={entitlement}
                        setNeedsFetchData={setNeedsFetchData}
                      />
                    )
                  )}
                  {isEntitlementAcsContainerHub(entitlement) && (
                    <EntitlementUsersTable
                      entitlementId={entitlement.entitlementId}
                      users={users}
                      isLoading={isLoading}
                      setNeedsFetchData={setNeedsFetchData}
                    />
                  )}
                </Box>
              )}
            </VerticalContainer>
          </BreadcrumbsContainer>
        </CenterContainer>
      </NoFooterContainer>
      <Footer />
    </PageContent>
  )
}

export default withAuthenticationRequired(EntitlementDetails)
