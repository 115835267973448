import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { styled, Link as MaterialLink } from '@material-ui/core'
import { HeaderTableCell } from '../../containers.jsx'
import MUIDataTable from 'mui-datatables'
import { formatDate } from '../../../utils'
import AssignUserToDojoEntitlementButton from '../../AssignUserToDojoEntitlementButton.jsx'
import UnassignButton from '../../UnassignButton.jsx'
import { ThemeProvider } from '@material-ui/core/styles'
import myadvThemeForEmbeddedEntitlementTable from '../../../asset/theme_embedded_entitlement_table'
import Loader from 'react-loader-spinner'
import {
  entitlementIdColumn,
  organizationKeyColumn,
  storageQuotaColumn,
} from '../../entitlements/EntitlementTable.jsx'

function getEntitlementType(entitlements) {
  if (entitlements && entitlements.length && entitlements.length > 0) {
    return entitlements[0].application
  } else {
    return 'Unknown'
  }
}

const TableContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(8),
  marginRight: theme.spacing(8),
  marginBottom: theme.spacing(2),
  boxShadow: 'none !important',
}))

const MoreEntitlementsContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const EmbeddedEntitlementTable = ({
  entitlements,
  hasMore,
  entitlementsOfOneProduct,
  total,
  setNeedsFetchData,
  orderItemSfid,
  isOrderItemsLoading,
}) => {
  const params = useParams()

  const entitlementType = getEntitlementType(entitlements)

  const applicationColumn = {
    name: 'application',
    label: 'Application',
    options: {
      customHeadRender: () => <HeaderTableCell>Application</HeaderTableCell>,
      sortThirdClickReset: true,
      customBodyRenderLite: (dataIndex) => {
        return entitlements[dataIndex].application
      },
    },
  }

  const assignedEmailColumn = {
    name: 'assignedEmail',
    label: 'Assigned Email',
    options: {
      customHeadRender: () => <HeaderTableCell>Assigned Email</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
    },
  }

  const vmNumberColumn = {
    name: 'vmNumber',
    label: 'VM Number',
    options: {
      customHeadRender: () => <HeaderTableCell>VM Number</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
    },
  }

  const startDateColumn = {
    name: 'startDate',
    label: 'Start Date',
    options: {
      customHeadRender: () => <HeaderTableCell>Start Date</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
      customBodyRenderLite: (dataIndex) => {
        if (entitlements[dataIndex].startDate) {
          return formatDate(entitlements[dataIndex].startDate)
        }
      },
    },
  }

  const expiryDateColumn = {
    name: 'expiryDate',
    label: 'Expiry Date',
    options: {
      customHeadRender: () => <HeaderTableCell>Expiry Date</HeaderTableCell>,
      filter: false,
      sortThirdClickReset: true,
      customBodyRenderLite: (dataIndex) => {
        if (entitlements[dataIndex].expiryDate) {
          return formatDate(entitlements[dataIndex].expiryDate)
        }
      },
    },
  }

  const assignStatusColumn = {
    name: 'assignStatus',
    label: 'Status',
    options: {
      customHeadRender: () => <HeaderTableCell>Status</HeaderTableCell>,
      filter: true,
      sortThirdClickReset: true,
    },
  }

  const modeColumn = {
    name: 'mode',
    label: 'Mode',
    options: {
      customHeadRender: () => <HeaderTableCell>Mode</HeaderTableCell>,
      filter: true,
      sortThirdClickReset: true,
    },
  }

  const assignButtonColumn = {
    name: '',
    label: '',
    options: {
      setCellProps: () => ({
        align: 'right',
      }),
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        switch (entitlements[dataIndex].assignStatus) {
          case 'Unassigned':
            return (
              <AssignUserToDojoEntitlementButton
                targetEntitlement={entitlements[dataIndex]}
                entitlementsOfOneProduct={entitlementsOfOneProduct}
                setNeedsFetchData={setNeedsFetchData}
              />
            )
          case 'Pending':
          case 'Assigned':
            return (
              <UnassignButton
                targetEntitlement={entitlements[dataIndex]}
                setNeedsFetchData={setNeedsFetchData}
              />
            )
          case 'In Progress':
          case 'Completed':
          case 'Expired':
          case 'Deactivated':
            return
          default:
            return
        }
      },
    },
  }

  // Columns displayed for each entitlement type
  const columns = {
    OnlineTraining: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      assignedEmailColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
      assignButtonColumn,
    ],
    TECloud: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      vmNumberColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
      modeColumn,
    ],
    SmartInsight: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      vmNumberColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
      modeColumn,
    ],
    ContainerHub: [
      applicationColumn,
      entitlementIdColumn(entitlements),
      storageQuotaColumn(entitlements),
      organizationKeyColumn,
      startDateColumn,
      expiryDateColumn,
      assignStatusColumn,
    ],
    Unknown: [],
  }[entitlementType]

  const options = {
    search: false,
    filter: false,
    viewColumns: false,
    print: false,
    download: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    pagination: false,
    elevation: 0,
    jumpToPage: false,
    setTableProps: () => ({ className: 'MuiTableCell-test' }),
    textLabels: {
      body: {
        noMatch: isOrderItemsLoading ? (
          <Loader type="ThreeDots" color="grey" />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
  }

  return (
    <ThemeProvider theme={myadvThemeForEmbeddedEntitlementTable}>
      <TableContainer>
        <MUIDataTable
          title={`Entitlements${hasMore ? ` (5/${total})` : ''}`}
          data={entitlements}
          columns={columns}
          options={options}
          size="small"
        />
        {hasMore && (
          <MoreEntitlementsContainer>
            <Link
              to={`/orders/${params.orderSfid}/order-items/${orderItemSfid}`}
              component={MaterialLink}
            >
              Show all entitlements
            </Link>
          </MoreEntitlementsContainer>
        )}
      </TableContainer>
    </ThemeProvider>
  )
}

export default EmbeddedEntitlementTable
