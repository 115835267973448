import { DateTime } from 'luxon'
const entitlementIsHistory = (entitlement) => {
  if (entitlement.assignStatus === 'Deactivated') {
    return true
  }

  if (entitlement.expiryDate === null) {
    return false
  }

  const now = new Date()

  const epochMillis = DateTime.fromISO(entitlement.expiryDate).toMillis()
  return now.getTime() > epochMillis
}

export default entitlementIsHistory
