import React from 'react'
import { Tooltip, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { OPEN_DIALOG } from '../redux/dialogReducer'
import { CLOSE_ALERT } from '../redux/alertReducer'
import { fifteenMinutesHaveElapsedSince } from '../utils/fifteenMinutesHaveElapsedSince'
import { FIFTEEN_MINUTES_STRING } from './UnassignButton'
import AssignUserToDojoEntitlementDialog from './dialog/assignUserToDojoEntitlement'
import isEntitlementDojo from '../utils/isEntitlementDojo'

// only show an "Assign" button, if 15 minutes have elapsed since last change
const AssignUserToDojoEntitlementButton = ({
  targetEntitlement,
  entitlementsOfOneProduct,
  setNeedsFetchData,
}) => {
  const dispatch = useDispatch()

  const isDisabled =
    // fifteen minutes rule is only valid for dojo
    isEntitlementDojo(targetEntitlement) &&
    !fifteenMinutesHaveElapsedSince(targetEntitlement.lastAssignmentChangedDate)

  return isDisabled ? (
    <Tooltip title={FIFTEEN_MINUTES_STRING}>
      <div>
        <Button variant="contained" size="small" color="primary" disabled>
          Assign user to Entitlement
        </Button>
      </div>
    </Tooltip>
  ) : (
    <Button
      variant="contained"
      size="small"
      color="primary"
      onClick={() => {
        dispatch({
          type: OPEN_DIALOG,
          component: AssignUserToDojoEntitlementDialog,
          props: {
            targetEntitlement,
            entitlementsOfOneProduct,
            setNeedsFetchData,
          },
        })
        dispatch({
          type: CLOSE_ALERT,
        })
      }}
    >
      Assign user to Entitlement
    </Button>
  )
}

export default AssignUserToDojoEntitlementButton
