import React from 'react'
import { IconButton, styled, Tooltip } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Highlighter from 'react-highlight-words'
import { CLOSE_ALERT } from '../../../redux/alertReducer'
import { useDispatch } from 'react-redux'

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
})

const LeftContainer = styled('div')({
  display: 'flex',
  width: '80%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

const SimpleLineItem = styled('div')({
  marginBottom: 4,
  display: 'flex',
  width: '100%',
  overflow: 'hidden',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

const TitleLineItem = styled('div')({
  marginBottom: 4,
  display: 'flex',
  width: '100%',
  overflow: 'hidden',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: 16,
})
const UserItem = ({
  user,
  searchWords,
  statusLabel,
  showAssignButton,
  assign,
  style,
}) => {
  const dispatch = useDispatch()
  return (
    <div style={style}>
      <ContentContainer>
        <LeftContainer>
          <TitleLineItem>
            {/* Name */}
            <Highlighter
              searchWords={searchWords}
              textToHighlight={`${user.lastName || '-'}, ${
                user.firstName || '-'
              }`}
            />
          </TitleLineItem>
          <SimpleLineItem>
            {/* Email */}
            <Highlighter
              searchWords={searchWords}
              textToHighlight={user.email}
            />
          </SimpleLineItem>
          {/* Status */}
          <SimpleLineItem>{statusLabel}</SimpleLineItem>
        </LeftContainer>

        {showAssignButton ? (
          <Tooltip title="assign user to entitlement">
            <IconButton
              onClick={() => {
                dispatch({ type: CLOSE_ALERT })
                assign(user.email)
              }}
              color="primary"
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div />
        )}
      </ContentContainer>
    </div>
  )
}

export default UserItem
