import {
  Badge,
  colors,
  createStyles,
  styled,
  Typography,
  withStyles,
} from '@material-ui/core'
import sortBy from 'lodash/sortBy'
import React, { useEffect, useState, useCallback } from 'react'

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: 20,
      top: 33,
    },
  })
)(Badge)

const ButtonContent = styled('div')(({ ...props }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '8px',
  height: 50,
  width: 'auto',
  cursor: 'pointer',
  background: props.isActive ? colors.grey[200] : 'white',
}))

const ProductSection = ({
  entitlements,
  tableContent,
  onSelect,
  selectedProductCode,
}) => {
  const [section, setSection] = useState([])

  const sectionElements = useCallback(async () => {
    const elements = []
    for (const [key, value] of Object.entries(entitlements)) {
      if (value.length > 0)
        elements.push(
          <>
            <Typography variant="h2" style={{ marginBottom: 16 }}>
              {key === 'OnlineTraining' && 'Online Training'}
              {key === 'TECloud' && 'TECloud'}
              {key === 'SmartInsight' && 'Smart Insight'}
              {key === 'ContainerHub' && 'Container Hub'}
            </Typography>
            {sortBy(value, (orderItem) => orderItem.productName).map(
              (orderItem) => (
                <StyledBadge
                  badgeContent={
                    tableContent[orderItem.productCode].active &&
                    tableContent[orderItem.productCode].active.filter(
                      (activeEntitlement) =>
                        // On the badge only nr of unassigned entitlements are shown
                        activeEntitlement.assignStatus === 'Unassigned'
                    ).length
                  }
                  color="primary"
                  key={orderItem.productCode}
                >
                  <div onClick={() => onSelect(orderItem.productCode)}>
                    <ButtonContent
                      tabIndex={0}
                      isActive={
                        selectedProductCode &&
                        selectedProductCode === orderItem.productCode
                      }
                    >
                      <div
                        style={{
                          fontWeight: 600,
                          width: 250,
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {orderItem.productName}
                      </div>
                      <div>{orderItem.productCode}</div>
                    </ButtonContent>
                  </div>
                </StyledBadge>
              )
            )}
          </>
        )
    }
    setSection(elements)
  }, [entitlements, onSelect, selectedProductCode, tableContent])

  useEffect(() => {
    if (entitlements && Object.keys(entitlements).length > 0) {
      sectionElements()
    }
  }, [entitlements, sectionElements])

  return section
}

export default ProductSection
