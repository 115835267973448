import axios from 'axios'

// API client
function makeApiClient() {
  return {
    get: async function (path, token) {
      return await axios
        .get(path, {
          baseURL: process.env.REACT_APP_BACKEND_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return response
        })
        .catch((err) => {
          return err.response
        })
    },

    post: async function (path, token, data) {
      return await axios
        .post(path, data, {
          baseURL: process.env.REACT_APP_BACKEND_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return response
        })
        .catch((err) => {
          return err.response
        })
    },
    delete: async function (path, token, data) {
      return await axios
        .delete(path, {
          baseURL: process.env.REACT_APP_BACKEND_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          data: data,
        })
        .then((response) => {
          return response
        })
        .catch((err) => {
          return err.response
        })
    },
  }
}

const ApiClient = new makeApiClient()
export default ApiClient
