import React from 'react'
import { Tooltip, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { OPEN_DIALOG } from '../redux/dialogReducer'
import { CLOSE_ALERT } from '../redux/alertReducer'
import { DateTime } from 'luxon'
import { fifteenMinutesHaveElapsedSince } from '../utils/fifteenMinutesHaveElapsedSince'
import UnassignDialog from './dialog/UnassignDialog'

export const FIFTEEN_MINUTES_STRING =
  'You cannot change status, because 15 minutes have not elapsed since the last change.'
const TEN_DAYS_STRING =
  'You cannot unassign once 10 days have elapsed after assignment.'

// only show an "Unassign" button, if
//   - 15 minutes have elapsed since assign, and
//   - 10 days have not elapsed since assign

const UnassignButton = ({ targetEntitlement, setNeedsFetchData }) => {
  const dispatch = useDispatch()

  const isDisabled =
    DateTime.fromISO(targetEntitlement.startDate)
      .plus({ days: 11 })
      .toFormat('yyyy-MM-dd') <= DateTime.now().toFormat('yyyy-MM-dd') ||
    !fifteenMinutesHaveElapsedSince(targetEntitlement.lastAssignmentChangedDate)

  return isDisabled ? (
    <Tooltip
      title={
        !fifteenMinutesHaveElapsedSince(
          targetEntitlement.lastAssignmentChangedDate
        )
          ? FIFTEEN_MINUTES_STRING
          : TEN_DAYS_STRING
      }
      arrow
    >
      <span>
        <Button variant="contained" size="small" color="secondary" disabled>
          Unassign
        </Button>
      </span>
    </Tooltip>
  ) : (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      onClick={() => {
        dispatch({
          type: OPEN_DIALOG,
          component: UnassignDialog,
          props: {
            targetEntitlement,
            setNeedsFetchData,
          },
        })
        dispatch({
          type: CLOSE_ALERT,
        })
      }}
    >
      Unassign
    </Button>
  )
}

export default UnassignButton
