import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import ApiClient from '../common/ApiClient.js'
import useDebounced from './useDebounced'

const useUsers = ({ initialPageSize }) => {
  const { getAccessTokenSilently } = useAuth0()

  const [users, setUsers] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize || 10)
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [query, debouncedQuery, setQuery] = useDebounced('')

  useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true)
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      })
      await ApiClient.get(
        `/v2/users?per_page=${pageSize}&page=${page}&query=${debouncedQuery}`,
        token
      )
        .then((response) => {
          setUsers(response.data.users)
          setCount(response.data.total)
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false)
        })
    }
    getUsers()
  }, [getAccessTokenSilently, page, pageSize, debouncedQuery])

  return {
    isLoading,
    users,
    onChangePage: (currentPage) => setPage(currentPage),
    onChangeRowsPerPage: (rowsPerPage) => setPageSize(rowsPerPage),
    count,
    page,
    pageSize,
    setQuery,
    query,
  }
}

export default useUsers
