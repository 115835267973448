import React, { useState, useEffect } from 'react'
import {
  styled,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core'
import UserItem from './UserItem'
import SearchIcon from '@material-ui/icons/Search'
import { FixedSizeList } from 'react-window'
import sortBy from 'lodash.sortby'
import useOwners from '../../../hooks/useOwners'

const ContentContainer = styled('div')({
  padding: '10px 0px',
})

const LIST_HEIGHT = 660

const ProgressContainer = styled('div')({
  width: '100%',
  height: LIST_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export default function UserWithDelegationTable({
  orderSfid,
  ownerEmail,
  delegateUser,
  deleteUser,
}) {
  const [clicked, setClicked] = useState(false)
  const [delegatedOwnerUsers, setDelegatedOwnerUsers] = useState([])
  const [originalOwnerUsers, setOriginalOwnerUsers] = useState([])
  const [restUsers, setRestUsers] = useState([])

  const { users, isLoading, setQuery, query, count } = useOwners({
    orderSfid,
  })

  useEffect(() => {
    const delegatedOwnerUsers = sortBy(
      users
        ? users.filter((user) => {
            return user.delegationStatus !== 'none' && user.email !== ownerEmail
          })
        : [],
      (user) => user.lastName
    )
    setDelegatedOwnerUsers(delegatedOwnerUsers)

    const originalOwnerUsers = sortBy(
      users
        ? users.filter((user) => {
            return user.email === ownerEmail
          })
        : [],
      (user) => user.lastName
    )
    setOriginalOwnerUsers(originalOwnerUsers)

    const restUsers = sortBy(
      users
        ? users.filter((user) => {
            return user.email !== ownerEmail && user.delegationStatus === 'none'
          })
        : [],
      (user) => user.lastName
    )
    setRestUsers(restUsers)
  }, [users, ownerEmail])

  const usersToShow = [
    ...originalOwnerUsers,
    ...delegatedOwnerUsers,
    ...restUsers,
  ]

  const renderRow = ({ index, style }) => (
    <UserItem
      style={style}
      key={usersToShow[index].email}
      ownershipType={
        usersToShow[index].email === ownerEmail
          ? 'original-owner'
          : usersToShow[index].delegationStatus === 'none'
          ? 'none'
          : 'delegated-owner'
      }
      delegateUser={(email) =>
        delegateUser(
          email,
          () => setClicked(true),
          () => setClicked(false),
          () => setClicked(false)
        )
      }
      deleteUser={(email) =>
        deleteUser(
          email,
          () => setClicked(true),
          () => setClicked(false),
          () => setClicked(false)
        )
      }
      user={usersToShow[index]}
      searchWords={[query]}
    />
  )

  return (
    <ContentContainer>
      <TextField
        style={{
          margin: '28px 16px 34px 0px',
          width: 485,
        }}
        autoFocus
        placeholder="Search by first name, last name or email..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        helperText={isLoading ? '' : `${count} matching users found.`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {isLoading || clicked ? (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      ) : (
        <FixedSizeList
          height={LIST_HEIGHT}
          itemCount={usersToShow.length}
          itemSize={80}
          width={504}
          style={{ marginBottom: '20px' }}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </ContentContainer>
  )
}
