import entitlementIsHistory from './entitlementIsHistory'

export const orderItemHasNonHistoryEntitlement = (entitlements) => (
  orderItem
) => {
  const nonHistoryEntitlementsOfThisProduct = entitlements.filter(
    (entitlement) =>
      orderItem.productCode === entitlement.productCode &&
      !entitlementIsHistory(entitlement)
  )

  return nonHistoryEntitlementsOfThisProduct.length > 0
}
