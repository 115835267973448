import sortBy from 'lodash/sortBy'

const getDefaultProductCode = (orderItems, entitlements) => {
  if (
    !orderItems ||
    !entitlements ||
    orderItems.length === 0 ||
    entitlements.length === 0
  ) {
    return null
  }
  const teCloudEntitlements = entitlements.filter(
    (entitlement) => entitlement.application === 'TECloud'
  )

  const foundOrderItems = sortBy(
    orderItems.filter((orderItem) =>
      teCloudEntitlements.filter(
        (entitlement) => entitlement.productCode === orderItem.productCode
      )
    ),
    (orderItem) => orderItem.productName
  )

  return foundOrderItems.length > 0
    ? foundOrderItems[0].productCode
    : orderItems[0].productCode
}

export default getDefaultProductCode
