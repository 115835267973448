import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import ApiClient from '../common/ApiClient.js'
import useDebounced from './useDebounced.js'

const useOwners = ({ orderSfid }) => {
  const { getAccessTokenSilently } = useAuth0()

  const [users, setUsers] = useState([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [query, debouncedQuery, setQuery] = useDebounced('')

  useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true)
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      })
      await ApiClient.get(
        `/orders/${orderSfid}/owners?query=${debouncedQuery}`,
        token
      )
        .then((response) => {
          setUsers(response.data.users)
          setCount(response.data.total)
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false)
        })
    }
    getUsers()
  }, [getAccessTokenSilently, orderSfid, debouncedQuery])

  return {
    isLoading,
    users,
    count,
    setQuery,
    query,
  }
}

export default useOwners
