import React, { useState, useEffect, useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  styled,
  Button,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
  InputAdornment,
  Tabs,
  Tab,
  DialogActions,
  TextField,
} from '@material-ui/core'
import ApiClient from '../../../common/ApiClient.js'
import UserWithAssignStatusTable from './UserWithAssignStatusTable.jsx'
import getUsersWithStatus from './getUsersWithStatus.js'
import CloseIcon from '@material-ui/icons/Close'
import EmailIcon from '@material-ui/icons/Email'
import ConsentForEmailUseLegalText from '../ConsentForEmailUseLegalText'
import { useDispatch } from 'react-redux'
import { OPEN_ALERT } from '../../../redux/alertReducer.jsx'
import { CLOSE_DIALOG } from '../../../redux/dialogReducer.jsx'
import { CircularProgress } from '@material-ui/core'

const HintContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}))
const StyledContent = styled(DialogContent)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}))
const StyledTab = styled(Tab)({
  fontSize: 14,
  paddingLeft: 0,
  paddingRight: 20,
})

const SearchByMailContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
}))

export default function AssignUserToDojoEntitlementDialog({
  entitlementsOfOneProduct,
  targetEntitlement,
  setNeedsFetchData,
}) {
  const [users, setUsers] = useState([])
  const [assignedUsers, setAssignedUsers] = useState([])
  const [pendingUsers, setPendingUsers] = useState([])
  const [inProgressUsers, setInProgressUsers] = useState([])
  const [completedUsers, setCompletedUsers] = useState([])
  const [expiredUsers, setExpiredUsers] = useState([])
  const [deactivatedUsers, setDeactivatedUsers] = useState([])
  const [isUserLoading, setIsUserLoading] = useState(true)
  const [mode, setMode] = useState('by-search') // 'by-search' or 'by-email'
  const [inProgress, setInProgress] = useState(false)

  const { getAccessTokenSilently } = useAuth0()
  // The value inpuuted to the email field
  const [inputValue, setInputValue] = useState('')
  const handleChange = (event) => {
    setInputValue(event.target.value)
  }
  const [emailError, setEmailError] = useState(false)

  const dispatch = useDispatch()

  const getUsers = useCallback(async () => {
    setIsUserLoading(true)
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    })
    await ApiClient.get('/users', token)
      .then((response) => {
        setUsers(response.data.users)
      })
      .catch(() => {})
      .finally(() => {
        setIsUserLoading(false)
      })
  }, [getAccessTokenSilently])

  const assign = async (
    email,
    onStartProcess = () => {},
    onSuccess = () => {},
    onFailure = () => {}
  ) => {
    // Prints the error in case the user doesn't exist or the value doesn't contain atmark
    const request = {
      entitlementId: targetEntitlement.entitlementId,
      email,
    }
    onStartProcess()
    setInProgress(true)
    // Execute API
    const token = await getAccessTokenSilently()
    await ApiClient.post('/assignment', token, request)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: OPEN_ALERT,
            msg: `Entitlement ${
              targetEntitlement && targetEntitlement.entitlementId
                ? targetEntitlement.entitlementId
                : ''
            } has been assigned to ${email}.`,
            severity: 'success',
          })
          // Fetch data from backend again
          setNeedsFetchData(true)
          handleClose()
          getUsers()
          onSuccess()
        } else {
          dispatch({
            type: OPEN_ALERT,
            msg: 'Error occurred. Please contact SMB-myAdvantest-Admin@advantest.com for assistance.',
            severity: 'error',
            action: (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(
                    'SMB-myAdvantest-Admin@advantest.com'
                  )
                }}
              >
                Copy email
              </Button>
            ),
          })
          onFailure()
        }
      })
      .catch(() => {
        onFailure()
      })
      .finally(() => {
        setInProgress(false)
      })
  }

  const handleClose = () => {
    dispatch({ type: CLOSE_DIALOG })
    // Initialize user email
    setInputValue('')
    // Initialize the error field
    setEmailError(false)
  }

  useEffect(() => {
    getUsers()
    const productCode = entitlementsOfOneProduct[0].productCode
    setAssignedUsers(
      getUsersWithStatus(entitlementsOfOneProduct, 'Assigned', productCode)
    )
    setPendingUsers(
      getUsersWithStatus(entitlementsOfOneProduct, 'Pending', productCode)
    )
    setInProgressUsers(
      getUsersWithStatus(entitlementsOfOneProduct, 'In Progress', productCode)
    )
    setCompletedUsers(
      getUsersWithStatus(entitlementsOfOneProduct, 'Completed', productCode)
    )
    setExpiredUsers(
      getUsersWithStatus(entitlementsOfOneProduct, 'Expired', productCode)
    )
    setDeactivatedUsers(
      getUsersWithStatus(entitlementsOfOneProduct, 'Deactivated', productCode)
    )
  }, [getAccessTokenSilently, entitlementsOfOneProduct, getUsers])

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6">
          Assign user to entitlement: {targetEntitlement.entitlementId}
        </Typography>
        <CloseButton aria-label="close" onClick={handleClose} color="secondary">
          <CloseIcon color="secondary" />
        </CloseButton>
      </StyledDialogTitle>
      <StyledContent>
        <HintContainer>
          Find the user you would like to assign this entitlement to. You can
          also assign to a user via email, if the person has not registered yet.
        </HintContainer>
        <HintContainer>
          A user to whom the entitlement is assigned can use the entitlement.
        </HintContainer>
        <HintContainer>
          Unassignment is not possible for 10 days after being assigned.
        </HintContainer>
        <Tabs
          value={mode}
          onChange={() =>
            setMode(mode === 'by-email' ? 'by-search' : 'by-email')
          }
          indicatorColor="primary"
          textColor="primary"
        >
          <StyledTab label="Search registered users" value="by-search" />
          <StyledTab label="Assign via email" value="by-email" />
        </Tabs>
        {mode === 'by-search' ? (
          <UserWithAssignStatusTable
            users={users}
            assign={assign}
            assignedUsers={assignedUsers}
            pendingUsers={pendingUsers}
            inProgressUsers={inProgressUsers}
            completedUsers={completedUsers}
            expiredUsers={expiredUsers}
            deactivatedUsers={deactivatedUsers}
            isUserLoading={isUserLoading}
            setIsUserLoading={setIsUserLoading}
          />
        ) : (
          <SearchByMailContainer>
            <HintContainer>
              <ConsentForEmailUseLegalText />
            </HintContainer>
            <TextField
              style={{ marginBottom: 16 }}
              value={inputValue}
              onChange={handleChange}
              autoFocus
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
              label="email"
              type="email"
              helperText={emailError ? 'Incorrect email' : ''}
              error={emailError}
            />
            <DialogActions>
              {inProgress ? (
                <CircularProgress style={{ marginRight: 20 }} />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      var reg =
                        /* eslint-disable-next-line */
                        /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

                      if (!inputValue || !reg.test(inputValue)) {
                        setEmailError(true)
                        return
                      }
                      assign(inputValue)
                    }}
                  >
                    Assign
                  </Button>
                </>
              )}
            </DialogActions>
          </SearchByMailContainer>
        )}
      </StyledContent>
    </>
  )
}
