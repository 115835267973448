import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import DialogContent from '@material-ui/core/DialogContent'
import { styled, Typography, IconButton, Link, Button } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import ApiClient from '../../../common/ApiClient.js'
import UserWithDelegationTable from './UserWithDelegationTable.js'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { OPEN_ALERT } from '../../../redux/alertReducer.jsx'
import { CLOSE_DIALOG } from '../../../redux/dialogReducer.jsx'

const HintContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledContent = styled(DialogContent)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}))

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}))

export default function DelegateDialog({ delegatedOrder }) {
  const { getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()

  const delegateUser = async (
    email,
    onStartProcess = () => {},
    onSuccess = () => {},
    onFailure = () => {}
  ) => {
    onStartProcess()
    // Execute API
    const token = await getAccessTokenSilently()
    await ApiClient.post('/delegate', token, {
      orderSfid: delegatedOrder.orderSfid,
      email: email,
    }).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: OPEN_ALERT,
          msg: `Order ${
            delegatedOrder && delegatedOrder.orderNumber
              ? delegatedOrder.orderNumber
              : ''
          } has been delegated to ${email}.`,
          severity: 'success',
        })
        onSuccess()
      } else {
        dispatch({
          type: OPEN_ALERT,
          msg: 'Error occurred and the ownership was not delegated. Please contact support for assistance.',
          severity: 'error',
          action: (
            <Link href="mailto:SMB-myAdvantest-Admin@advantest.com">
              <Button variant="contained" color="primary" size="small">
                Contact support
              </Button>
            </Link>
          ),
        })
        onFailure()
      }
    })
  }

  const deleteUser = async (
    email,
    onStartProcess = () => {},
    onSuccess = () => {},
    onFailure = () => {}
  ) => {
    onStartProcess()
    // Execute API
    const token = await getAccessTokenSilently()
    await ApiClient.delete('/delegate', token, {
      orderSfid: delegatedOrder.orderSfid,
      email: email,
    }).then((response) => {
      if (response.status === 200) {
        dispatch({
          type: OPEN_ALERT,
          msg: `Delegation to ${email} for Order ${delegatedOrder.orderNumber} has been removed.`,
          severity: 'success',
        })
        onSuccess()
      } else {
        dispatch({
          type: OPEN_ALERT,
          msg: 'Error occurred and the ownership was not deleted. Please contact support for assistance.',
          severity: 'error',
          action: (
            <Link href="mailto:SMB-myAdvantest-Admin@advantest.com">
              <Button variant="contained" color="primary" size="small">
                Contact support
              </Button>
            </Link>
          ),
        })

        onFailure()
      }
    })
  }

  const handleClose = () => {
    dispatch({ type: CLOSE_DIALOG })
  }

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6">
          Delegation of order: {delegatedOrder.orderNumber}
        </Typography>
        <CloseButton aria-label="close" onClick={handleClose} color="secondary">
          <CloseIcon color="secondary" />
        </CloseButton>
      </StyledDialogTitle>
      <StyledContent>
        <HintContainer>
          Find the user you would like to delegate this order to, or remove
          delegation from.
        </HintContainer>

        <HintContainer>
          A user who owns the order through delegation has the same permission
          on the order as the original owner, but ownership of the original
          owner cannot be removed.
        </HintContainer>

        <HintContainer>
          Removing a delegation will also remove the user from the ‘Admins’
          assignment of the entitlement.
        </HintContainer>

        <UserWithDelegationTable
          orderSfid={delegatedOrder.orderSfid}
          ownerEmail={delegatedOrder.ownerEmail}
          delegateUser={delegateUser}
          deleteUser={deleteUser}
        />
      </StyledContent>
    </>
  )
}
