import React from 'react'
import PropTypes from 'prop-types'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Route } from 'react-router-dom'

export function ProtectedRoute({ component, ...args }) {
  return (
    <Route component={withAuthenticationRequired(component, {})} {...args} />
  )
}

// Validator for argumments of ProtectedRoute
// If deleted, eslint outputs a warning
ProtectedRoute.propTypes = {
  component: PropTypes.any,
}

export default ProtectedRoute
