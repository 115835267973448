import { useState } from 'react'
import { useDebounce } from 'use-debounce'

const useDebounced = (initialValue = '') => {
  const [value, setValue] = useState(initialValue)
  const [debouncedValue] = useDebounce(value, 1000)

  return [value, debouncedValue, setValue]
}

export default useDebounced
