import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ApiClient } from '../../common'
import { OPEN_ALERT } from '../../redux/alertReducer'
import { useDispatch } from 'react-redux'
import { CLOSE_DIALOG } from '../../redux/dialogReducer'
import { CircularProgress } from '@material-ui/core'

const UnassignDialog = ({ targetEntitlement, setNeedsFetchData }) => {
  const unassignedEntitlement = targetEntitlement
  const { getAccessTokenSilently } = useAuth0()
  const [inProgress, setInProgress] = useState(false)

  const dispatch = useDispatch()

  const handleSubmit = async () => {
    const request = {
      entitlementId: unassignedEntitlement.entitlementId,
      email: unassignedEntitlement.assignedEmail,
    }

    setInProgress(true)
    // Execute API
    const token = await getAccessTokenSilently()
    await ApiClient.delete('/assignment', token, request)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: OPEN_ALERT,
            msg: `Successfully unassigned entitlement: ${
              unassignedEntitlement && unassignedEntitlement.entitlementId
                ? unassignedEntitlement.entitlementId
                : ''
            }.`,
            severity: 'success',
          })
          // Fetch data from backend agains
          setNeedsFetchData(true)
          setInProgress(false)
        } else {
          dispatch({
            type: OPEN_ALERT,
            msg: 'Error occurred. Please contact SMB-myAdvantest-Admin@advantest.com for assistance.',
            severity: 'error',
            action: (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => {
                  navigator.clipboard.writeText(
                    'SMB-myAdvantest-Admin@advantest.com'
                  )
                }}
              >
                Copy email
              </Button>
            ),
          })
          setInProgress(false)
        }
      })
      .catch(() => {
        setInProgress(false)
      })
    dispatch({ type: CLOSE_DIALOG })
  }

  const handleClose = () => dispatch({ type: CLOSE_DIALOG })

  return (
    <>
      <DialogTitle>Unassign User</DialogTitle>
      <DialogContent>
        {`Are you sure you want to unassign "${unassignedEntitlement.productName}"
        from "${unassignedEntitlement.assignedEmail}"?`}
        <DialogActions>
          {inProgress ? (
            <CircularProgress style={{ marginRight: 20 }} />
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={inProgress}
              >
                OK
              </Button>
            </>
          )}
        </DialogActions>
      </DialogContent>
    </>
  )
}

export default UnassignDialog
