const KILO = 1024
const MEGA = 1048576
const GIGA = 1073741824
const TERRA = 1099511627776

const formatStorageQuota = (numberOfBytes) => {
  const inTerra = numberOfBytes / TERRA
  if (inTerra >= 1) {
    return `${inTerra} TB`
  } else {
    const inGiga = numberOfBytes / GIGA
    if (inGiga >= 1) {
      return `${inGiga} GB`
    } else {
      const inMega = numberOfBytes / MEGA
      if (inMega >= 1) {
        return `${inMega} MB`
      } else {
        const inKilo = numberOfBytes / KILO
        if (inKilo >= 1) {
          return `${inMega} kB`
        } else {
          return `${numberOfBytes} Bytes`
        }
      }
    }
  }
}

export default formatStorageQuota
