import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import ApiClient from '../common/ApiClient.js'
import useDebounced from './useDebounced'

// using the V2 api (/v2) for orders
const useOrders = ({ initialPageSize }) => {
  const { getAccessTokenSilently } = useAuth0()

  const [orders, setOrders] = useState([])
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(initialPageSize || 10)
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [query, debouncedQuery, setQuery] = useDebounced('')

  useEffect(() => {
    setIsLoading(true)
    const getOrders = async () => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      })
      await ApiClient.get(
        `/v2/orders?per_page=${pageSize}&page=${page}&query=${debouncedQuery}&active=true`,
        token
      )
        .then((response) => {
          setOrders(response.data.orders)
          setCount(response.data.total)
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false)
        })
    }
    getOrders()
  }, [getAccessTokenSilently, page, pageSize, debouncedQuery])

  return {
    query,
    debouncedQuery,
    isLoading,
    orders,
    onChangePage: (currentPage) => setPage(currentPage),
    onChangeRowsPerPage: (rowsPerPage) => setPageSize(rowsPerPage),
    count,
    page,
    pageSize,
    setQuery,
  }
}

export default useOrders
