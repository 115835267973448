import { Card } from '@material-ui/core'
import Loader from 'react-loader-spinner'
import React from 'react'
import ProductSection from './ProductSection'
import filterOrderItemsByApplication from './filterOrderItemsByApplication'

export const productSelectorCardStyles = {
  width: 300,
  marginRight: 32,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px 0px',
}

const ProductSelector = ({
  orderItems,
  tableContent,
  selectedProductCode,
  onSelect,
  isLoading,
}) => {
  const entitlements = {
    OnlineTraining: filterOrderItemsByApplication(
      tableContent,
      orderItems,
      'OnlineTraining'
    ),
    TECloud: filterOrderItemsByApplication(tableContent, orderItems, 'TECloud'),
    SmartInsight: filterOrderItemsByApplication(
      tableContent,
      orderItems,
      'SmartInsight'
    ),
    ContainerHub: filterOrderItemsByApplication(
      tableContent,
      orderItems,
      'ContainerHub'
    ),
  }

  return (
    <Card style={productSelectorCardStyles}>
      {isLoading && <Loader type="ThreeDots" color="grey" />}
      <ProductSection
        entitlements={entitlements}
        tableContent={tableContent}
        selectedProductCode={selectedProductCode}
        onSelect={onSelect}
      />
    </Card>
  )
}

export default ProductSelector
