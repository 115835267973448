import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import myadvTheme from './asset/theme_react.js'
import CustomAlert from './component/dialog/apiAlert/Alert'
import './asset/theme_css.css'
import LoadingScreen from './component/LoadingScreen'
import { isIE11 } from './'
import { useSelector } from 'react-redux'
import { Alert } from '@material-ui/lab'
import Routes from './Routes'
import { Dialog, Snackbar } from '@material-ui/core'

function App() {
  const { isLoading, error } = useAuth0()
  const { msg, severity, open, action } = useSelector(
    (state) => state.alertState
  )
  const {
    component: Component,
    open: dialogOpen,
    props: dialogProps,
  } = useSelector((state) => state.dialogState)

  if (isLoading) {
    return (
      <Router>
        <LoadingScreen />
      </Router>
    )
  }

  if (
    error &&
    // in case of IE11 an error status occurs, but eventually it redirects to home, so don't show error message in case of IE11
    !isIE11
  ) {
    return (
      <Snackbar open={true}>
        <Alert severity="error" variant="filled">
          Authentication error, please try again.
        </Alert>
      </Snackbar>
    )
  }

  return (
    <ThemeProvider theme={myadvTheme}>
      <Routes />
      {open && (
        <CustomAlert
          message={msg}
          severity={severity}
          open={open}
          action={action}
        />
      )}
      {dialogOpen && (
        <Dialog open={dialogOpen} disableBackdropClick>
          <Component {...dialogProps} />
        </Dialog>
      )}
    </ThemeProvider>
  )
}

export default App
