import React from 'react'
import Header from './header/Header'
import { ThemeProvider } from '@material-ui/core/styles'
import myadvTheme from '../asset/theme_react.js'
import { isIE11 } from '..'
import { Redirect } from 'react-router'

const Callback = () => {
  // We only reach this page in case of IE11, so let's redirect, if we are here, and it is IE11
  if (isIE11) {
    return <Redirect to={'/'} />
  }

  return (
    <div>
      <ThemeProvider theme={myadvTheme}>
        <Header />
        <p>CallBack</p>
      </ThemeProvider>
    </div>
  )
}
export default Callback
