/*
 * Returns an array of emails of users who are assigned to
 * the given entitlements with given status and product code.
 */
export default function getUsersWithStatus(entitlements, status, productCode) {
  const resultUsers = []

  if (entitlements === undefined || entitlements.length === 0) {
    return resultUsers
  }

  for (let i = 0; i < entitlements.length; i++) {
    if (
      entitlements[i].assignStatus === status &&
      entitlements[i].productCode === productCode
    ) {
      resultUsers.push(entitlements[i].assignedEmail)
    }
  }
  return resultUsers
}
