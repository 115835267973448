import { DateTime } from 'luxon'

const unassignedEntitlementsWillExpireSoon = (entitlement) => {
  if (entitlement.expiryDate === null) {
    return false
  }
  const now = new Date()
  const epochSec = DateTime.fromISO(entitlement.expiryDate).toSeconds()
  return (
    entitlement.assignStatus === 'Unassigned' &&
    // expires in the next 15 days, but have not expired yet
    now.getTime() / 1000 + 15 * 24 * 60 * 60 > epochSec &&
    now.getTime() / 1000 < epochSec
  )
}

export default unassignedEntitlementsWillExpireSoon
