import React from 'react'
import {
  styled,
  DialogTitle,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core'
import AssignACSUsersList from './AssignACSUsersList'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { CLOSE_DIALOG } from '../../../redux/dialogReducer.jsx'

const HintContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}))
const StyledContent = styled(DialogContent)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}))

export default function AssignUserToAcsContainerHubEntitlementDialog({
  targetEntitlement,
  setNeedsFetchData,
}) {
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch({ type: CLOSE_DIALOG })
  }

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6">
          Assign user to entitlement: {targetEntitlement.entitlementId}
        </Typography>
        <CloseButton aria-label="close" onClick={handleClose} color="secondary">
          <CloseIcon color="secondary" />
        </CloseButton>
      </StyledDialogTitle>
      <StyledContent>
        <HintContainer>
          Find the user you would like to assign this entitlement to.
        </HintContainer>
        <HintContainer>
          A user to whom the entitlement is assigned can use the entitlement.
        </HintContainer>
        <HintContainer>
          Unassignment is not possible for 10 days after being assigned.
        </HintContainer>

        <AssignACSUsersList
          assignAsAdmin={false}
          targetEntitlement={targetEntitlement}
          handleClose={handleClose}
          setNeedsFetchData={setNeedsFetchData}
        />
      </StyledContent>
    </>
  )
}
