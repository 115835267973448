import React from 'react'
import { AppBar, styled, Toolbar, Link, Button } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'
import useResponsive from '../../hooks/useResponsive'
import { useLocation } from 'react-router'

export const getHeaderHeight = (device) => (device === 'sm' ? 110 : 70)

const Divider = styled('div')(({ theme }) => ({
  height: '30px',
  width: '0px',
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '0px 10px',
}))

const PieceContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})
const RowContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
})
const ColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
})

const StyledButton = styled(Button)({
  border: (props) => (props.isActive ? '1px solid black' : '1px solid white'),
})

const StyledAppBar = styled(AppBar)(({ device, theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  background: theme.palette.background.paper,
  height: getHeaderHeight(device),
  zIndex: 100,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxSizing: 'border-box',
}))

const renderActionAndEmail = (
  device,
  email,
  onLogout,
  onLogin,
  isAuthenticated
) => (
  <PieceContainer>
    {isAuthenticated ? (
      <StyledButton onClick={onLogout}>LOGOUT</StyledButton>
    ) : (
      <StyledButton onClick={onLogin}>LOGIN</StyledButton>
    )}
    {isAuthenticated && device !== 'sm' && device !== 'md' && (
      <>
        <Divider />
        {email}
      </>
    )}
  </PieceContainer>
)

const Header = () => {
  const device = useResponsive()
  const { user, logout, loginWithRedirect, isAuthenticated } = useAuth0()

  const onLogout = () =>
    logout({ returnTo: process.env.REACT_APP_ORIGIN + '/logout' })

  const onLogin = () => {
    loginWithRedirect()
  }
  const location = useLocation()
  return (
    <StyledAppBar
      position="static"
      color="inherit"
      elevation={0}
      device={device}
    >
      <Toolbar style={{ padding: '0px 16px' }}>
        <ColumnContainer>
          <RowContainer>
            <PieceContainer>
              <Link href="/" style={{ cursor: 'pointer', height: '58px' }}>
                <img src="/logo.svg" alt="advantest_logo" />
              </Link>
              <StyledButton
                isActive={location.pathname.includes('/orders')}
                href={'/orders'}
                style={{ marginLeft: 24 }}
              >
                ORDERS
              </StyledButton>
              <StyledButton
                href={'/entitlements'}
                style={{ marginLeft: 24 }}
                isActive={location.pathname.includes('/entitlements')}
              >
                ENTITLEMENTS
              </StyledButton>
            </PieceContainer>
            {device !== 'sm' &&
              renderActionAndEmail(
                device,
                user ? user.email : '',
                onLogout,
                onLogin,
                isAuthenticated
              )}
          </RowContainer>
          <RowContainer>
            <PieceContainer>
              {device !== 'sm' && (
                <>
                  <div style={{ width: 68 }} />
                </>
              )}
            </PieceContainer>
          </RowContainer>
          {device === 'sm' && (
            <>
              {renderActionAndEmail(
                device,
                user ? user.email : '',
                onLogout,
                onLogin,
                isAuthenticated
              )}
            </>
          )}
        </ColumnContainer>
      </Toolbar>
    </StyledAppBar>
  )
}

export default Header
