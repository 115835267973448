import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import ApiClient from '../common/ApiClient.js'

// using the old api (/) for order-items
// the old orders api returns order-items on "/orders" endpoint
// order-item is referred to as "Product" on the UI
const useOrderItems = ({ shouldFilterForRemaining = false }) => {
  const { getAccessTokenSilently } = useAuth0()

  const [orderItems, setOrderItems] = useState([])
  const [needsFetchData, setNeedsFetchData] = useState(true)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (needsFetchData) {
      const getOrderItems = async () => {
        setIsLoading(true)
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        })
        await ApiClient.get('/orders', token)
          .then((response) => {
            const orderItems = response.data.orders

            setOrderItems(
              shouldFilterForRemaining
                ? orderItems.filter((orderItem) => orderItem.remaining > 0)
                : orderItems
            )
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false)
          })
      }
      getOrderItems()
      setNeedsFetchData(false)
    }
  }, [getAccessTokenSilently, shouldFilterForRemaining, needsFetchData])

  return { isLoading, orderItems, setNeedsFetchData }
}

export default useOrderItems
