import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  styled,
  Paper,
  Grid,
  Card,
  CardHeader,
  Typography,
  CardContent,
} from '@material-ui/core'
import Header from '../header/Header'
import { PageContent, NoFooterContainer } from '../containers.jsx'
import Footer from '../footer/Footer'
import uniqBy from 'lodash/uniqBy'
import entitlementIsHistory from '../../utils/entitlementIsHistory'
import ProductCard from './ProductCard'
import { isProductTeCloud } from '../../utils/isProductTeCloud'
import OrderTable from '../orders/OrderTable'
import { orderItemHasNonHistoryEntitlement } from '../../utils/orderItemHasNonHistoryEntitlement'
import useOrderItems from '../../hooks/useOrderItems'
import useEntitlements from '../../hooks/useEntitlements'

const DashboardContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: '100%',
  maxWidth: 2000,
}))

const WelcomeCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',
}))

const CenterContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}))

const WelcomePaper = styled(Paper)(() => ({
  width: '40vw',
  height: 'auto',
  padding: `14px 30px`,
  whiteSpace: 'break-spaces',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '1.5rem',
  boxSizing: 'border-box',
  borderRadius: 4,
  marginBottom: 40,
}))

const Home = () => {
  const { user } = useAuth0()

  const { orderItems } = useOrderItems({ shouldFilterForRemaining: true })

  const { entitlements } = useEntitlements()

  return (
    <PageContent>
      <NoFooterContainer>
        <Header />
        <WelcomeCardContainer>
          <CenterContainer>
            <WelcomePaper elevation={2}>
              Welcome {user.given_name}!
            </WelcomePaper>
            <DashboardContainer>
              <Card style={{ width: '100%' }}>
                <CardHeader
                  title={
                    <Typography variant="h2">Your active products</Typography>
                  }
                />
                <CardContent>
                  <Grid container spacing={2}>
                    {uniqBy(orderItems, (orderItem) => orderItem.productCode)
                      //  A) either don't have this filter, but then we should show histoy entitlements too (filtering below shuold return true)
                      .filter(orderItemHasNonHistoryEntitlement(entitlements))
                      .map((orderItem) => {
                        const entitlementsOfThisProduct = entitlements.filter(
                          (entitlement) =>
                            orderItem.productCode === entitlement.productCode
                        )
                        const nonHistoryEntitlementsOfThisProduct =
                          entitlementsOfThisProduct.filter(
                            (entitlement) =>
                              // B) switch to !entitlemeentIsHistory, but then keep the above filter too
                              // true
                              !entitlementIsHistory(entitlement)
                          )

                        return (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                            justify="center"
                            key={orderItem.orderItemSfid}
                          >
                            <ProductCard
                              nonHistoryEntitlementsOfThisProduct={
                                nonHistoryEntitlementsOfThisProduct
                              }
                              orderItem={orderItem}
                              media={`https://images.myadvantest.com/${
                                isProductTeCloud(entitlementsOfThisProduct)
                                  ? 'tecloud'
                                  : 'dojo'
                              }/500x500/${orderItem.productCode}.png`}
                            />
                          </Grid>
                        )
                      })}
                  </Grid>
                </CardContent>
              </Card>
              <div style={{ height: 40 }}></div>
              <div style={{ width: '100%' }}>
                <OrderTable title="Your active orders" initialPageSize={5} />
              </div>
            </DashboardContainer>
          </CenterContainer>
        </WelcomeCardContainer>
      </NoFooterContainer>
      <Footer />
    </PageContent>
  )
}

export default Home
