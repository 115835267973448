import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import alertReducer from './redux/alertReducer'
import dialogReducer from './redux/dialogReducer'

const location = window.location
if (!location.host.includes('localhost') && location.protocol === 'http:') {
  location.replace(location.href.replace(/^http:/, 'https:'))
}

export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

const store = createStore(
  combineReducers({
    alertState: alertReducer,
    dialogState: dialogReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    redirectUri={process.env.REACT_APP_ORIGIN + '/callback'}
    cacheLocation="localstorage"
    useRefreshTokens={true}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
)
