import React from 'react'
import { Link, styled, SvgIcon } from '@material-ui/core'
import { IconConst } from '../icons/iconPaths'
import useResponsive from '../../hooks/useResponsive'

const isBigScreen = (device) => device !== 'sm' && device !== 'xs'

export const getFooterHeight = (device) =>
  device !== 'sm' && device !== 'xs' ? 59 : 180

const Container = styled('footer')(({ device }) => ({
  display: 'flex',
  flexDirection: isBigScreen(device) ? 'row' : 'column',
  justifyContent: isBigScreen(device) ? 'space-between' : 'space-around',
  alignItems: 'center',
  height: getFooterHeight(device),
  width: '100%',
}))

const LeftContainer = styled('div')({
  width: 300,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
})

const RightContainer = styled('div')(({ device }) => ({
  width: '300px !important',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: isBigScreen(device) ? 'flex-end' : 'center',
  alignItems: 'center',
}))

const SocialMediaContainer = styled('div')({
  width: 120,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
})

const CURRENT_YEAR = new Date().getFullYear()

const Footer = () => {
  const device = useResponsive()

  return (
    <Container device={device}>
      <LeftContainer>
        <Link href="https://my.advantest.com/static/contact/" target="_blank">
          Contact
        </Link>
        <Link href="https://my.advantest.com/static/terms/" target="_blank">
          Terms of Use
        </Link>
        <Link href="https://my.advantest.com/static/privacy/" target="_blank">
          Privacy Policy
        </Link>
      </LeftContainer>
      {isBigScreen(device) && (
        <div>&copy; Copyright {CURRENT_YEAR} ADVANTEST CORPORATION</div>
      )}
      <RightContainer device={device}>
        <SocialMediaContainer>
          <Link
            href="https://www.facebook.com/advantestamericainc/"
            target="_blank"
          >
            <SvgIcon viewBox="0 0 28 28">
              <path d={IconConst.Action.Facebook.d} />
            </SvgIcon>
          </Link>
          <Link href="https://twitter.com/Advantest_ATE" target="_blank">
            <SvgIcon viewBox="0 0 28 28">
              <path d={IconConst.Action.Twitter.d} />
            </SvgIcon>
          </Link>
        </SocialMediaContainer>
      </RightContainer>
      {!isBigScreen(device) && (
        <div>&copy; Copyright {CURRENT_YEAR} ADVANTEST CORPORATION</div>
      )}
    </Container>
  )
}

export default Footer
