/*取得したOrderをOrderSfidごとにまとめる関数
異なる{orderSfid,orderNumber, orderDate}
を含む配列を返す
 */
export default function groupOrderItemsIntoOrders(orderItems) {
  const uniqueOrders = []
  const uniqueOrderSfids = []
  if (orderItems === undefined || orderItems.length === 0) {
    return uniqueOrders
  }

  for (let i = 0; i < orderItems.length; i++) {
    if (!uniqueOrderSfids.includes(orderItems[i].orderSfid)) {
      uniqueOrders.push({
        orderSfid: orderItems[i].orderSfid,
        orderNumber: orderItems[i].orderNumber,
        orderDate: orderItems[i].orderDate,
        ownerEmail: orderItems[i].ownerEmail,
        products: orderItems
          .filter(
            (orderItem) => orderItem.orderSfid === orderItems[i].orderSfid
          )
          .map((orderItem) => ({
            productName: orderItem.productName,
            productCode: orderItem.productCode,
            quantity: orderItem.quantity,
            productDetail: `${orderItem.quantity} x ${orderItem.productCode}`,
          })),
      })
      uniqueOrderSfids.push(orderItems[i].orderSfid)
    }
  }
  return uniqueOrders
}
