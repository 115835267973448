import { createTheme } from '@material-ui/core/styles'

export const fontRubik = ['Rubik', 'sans-serif'].join(',')

export const boxShadow = {
  basic: '0 2px 6px 0 rgba(38,50,56,0.06)',
  basic_hover:
    '0 0 1px 0 rgba(38,50,56,0.15), 0 10px 30px 0 rgba(38,50,56,0.18) !important',

  // notification doesn't have a default state effect.
  notification_hover:
    '0 0 1px 0 rgba(38,50,56,0.15), 0 5px 20px 0 rgba(38,50,56,0.12) !important',
}

// ================================================================================================
// === colors
// ================================================================================================
export const colors = {
  primary: '#007AFF',
  primary_hover_bg: '#0060C9',
  secondary: '#90A4AE',
  secondary_hover_bg: '#6D7D85',
  brand: '#A00145', //R160 + G1 + B69
  error: '#FF3B30',
  background: '#ECEFF1',
  white: '#FFFFFF',
  black: '#000000',
  grey: {
    100: '#ECEFF1', // background
    200: '#CFD8DC', // grey 1
    300: '#B0BEC5', // grey 2
    400: '#90A4AE', // secondary
    500: '#607D8B', // grey 3
    600: '#263238', // grey 4
  },
  success: '#4CD964',
  warning: '#FF9500',
}

const myadvTheme = createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    error: { main: colors.error },
    background: { default: colors.background, paper: colors.white },
    grey: colors.grey,
    info: { main: colors.primary },
    success: { main: colors.success },
    warning: { main: colors.warning },
    getContrastText: (color) =>
      color === colors.white ||
      color === colors.background ||
      color === colors.grey[200] ||
      color === colors.grey[300]
        ? colors.black
        : colors.white,
  },
  typography: {
    fontWeightLight: 300,
    fontFamily: fontRubik,
    fontSize: 14,
    htmlFontSize: 14,
    button: {
      fontSize: 14,
      fontWeight: 300,
      color: colors.white,
    },
    allVariants: {
      letterSpacing: 0,
    },
    h1: {
      fontSize: 30,
      fontWeight: 300,
      color: colors.grey[600],
    },
    h2: {
      fontSize: 24,
      fontWeight: 300,
      color: colors.grey[600],
    },
    h3: {
      fontSize: 18,
      fontWeight: 300,
      color: colors.grey[600],
    },
    h4: {
      fontSize: 14,
      fontWeight: 300,
      color: colors.grey[400],
      letterSpacing: 0,
    },
    h5: {
      fontSize: 13,
      fontWeight: 300,
      color: '#455A64',
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
      color: colors.grey[500],
    },
    body2: {
      fontSize: 14,
      fontWeight: 300,
      color: colors.grey[500],
    },
    caption: {
      fontSize: 16,
      fontWeight: 300,
      color: colors.grey[600],
    },
  },
  props: {
    //..
  },
  overrides: {
    //..
    MuiCard: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiPaper: {
      root: {
        boxShadow: `${boxShadow.basic} !important`,
        '&.has-hover:hover': {
          boxShadow: `${boxShadow.basic_hover} !important`,
          marginLeft: -10,
          marginRight: -10,
          paddingLeft: 10,
          paddingRight: 10,
          width: 'calc(100% + 20px)',
        },
      },
    },
    MuiButton: {
      // -------------------------------------------------- MuiButton > root
      root: {
        textTransform: 'none',
        fontFamily: fontRubik,
        transition: 'inherit',
        minWidth: '64px',
        boxShadow: '',
      },
      // -------------------------------------------------- MuiButton > contained
      contained: {
        border: 0,
        margin: 0,
        display: 'inline-flex',
        outline: 0,
        position: 'relative',
        fontFamily: fontRubik,
        fontWeight: 400,
        letterSpacing: 0,
        color: colors.white,
        lineHeight: 'normal',
        borderRadius: 4,
        fontSize: 14,
        textTransform: 'inherit',
        padding: '5px 10px',
        boxShadow: 'none',
      },
      // -------------------------------------------------- MuiButton > containedPrimary
      containedPrimary: {
        backgroundColor: colors.primary,
        fontFamily: fontRubik,
        height: 40,
        '&:hover': {
          backgroundColor: colors.primary_hover_bg,
        },
      },
      // -------------------------------------------------- MuiButton > containedSecondary
      containedSecondary: {
        backgroundColor: colors.secondary,
        color: colors.white,
        height: 40,
        '&:hover': {
          backgroundColor: colors.secondary_hover_bg,
        },
      },
      // -------------------------------------------------- MuiButton > containedSizeSmall
      sizeSmall: {
        height: 24,
        fontSize: 12,
        color: colors.white,
        padding: '5px 10px',
        lineHeight: 'normal',
      },
      // -------------------------------------------------- MuiButton > containedSizeLarge
      sizeLarge: {
        backgroundColor: colors.secondary,
        height: 24,
        padding: '5px 10px',
        fontSize: 12,
        color: colors.white,
        lineHeight: 'normal',
        '&:hover': {
          backgroundColor: colors.secondary_hover_bg,
        },

        // -------------------------------------------------- MuiButton > startIcon
        startIcon: {
          path: '',
        },
        text: {
          fontFamily: fontRubik,
        },
        // -------------------------------------------------- MuiButton > outlinedPrimary
      },
      outlinedPrimary: {
        backgroundColor: '#1973e71a',
        fontFamily: fontRubik,
        fontSize: '12px',
        color: '#4d9bff',
        lineHeight: 'inherit',
        alignItems: 'inherit',
        height: '24px',
        borderColor: '#00000000',
        '&:hover': {
          backgroundColor: '#00000014',
          borderColor: '#00000000',
        },
        // -------------------------------------------------- MuiButton > outlinedSecondary
      },
      outlinedSecondary: {
        backgroundColor: '#1973e71a',
        height: '24px',
        fontSize: '12px',
        paddingLeft: '10px',
        paddingRight: '5px',
        paddingBottom: '5px',
        color: '#1973e7',
        lineHeight: 'inherit',
        alignItems: 'inherit',
        borderColor: '#00000000',
        '&:hover': {
          backgroundColor: '#00000014',
          borderColor: '#00000000',
        },
      },
    },

    // ==============================================================================================
    // === palette
    // ==============================================================================================
  },
})

export default myadvTheme
