import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const SimpleDetailItem = ({ label, value }) => {
  return (
    <Box display="flex" flexDirection="row" sx={{ maxWidth: '1000px' }}>
      <Typography style={{ minWidth: '200px' }}>{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  )
}

export default SimpleDetailItem
