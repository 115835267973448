import { useState, useEffect } from 'react'
import { ApiClient } from '../common'
import { useAuth0 } from '@auth0/auth0-react'

// using the old api (/) for entitlements
const useEntitlements = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [entitlements, setEntitlements] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [needsFetchData, setNeedsFetchData] = useState(true)

  useEffect(() => {
    if (needsFetchData) {
      const getEntitlements = async () => {
        setIsLoading(true)
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        })
        await ApiClient.get('/entitlements', token)
          .then((response) => {
            const entitlements = response.data.entitlements
            setEntitlements(entitlements)
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false)
          })
      }
      getEntitlements()
      setNeedsFetchData(false)
    }
  }, [getAccessTokenSilently, needsFetchData])

  return { entitlements, isLoading, setNeedsFetchData }
}

export default useEntitlements
