// Unassigned entitlements are the most important, they will come first.
// Otherwise in general, sort entitlements by entitlement id, higher values first.

import { orderBy } from 'lodash'

const sortEntitlements = (entitlements) => {
  const unassignedEntitlements = orderBy(
    entitlements.filter(
      (entitlement) => entitlement.assignStatus === 'Unassigned'
    ),
    (entitlement) => entitlement.entitlementId,
    'desc'
  )
  const restEntitlements = orderBy(
    entitlements.filter(
      (entitlement) => entitlement.assignStatus !== 'Unassigned'
    ),
    (entitlement) => entitlement.entitlementId,
    'desc'
  )

  return [...unassignedEntitlements, ...restEntitlements]
}

export default sortEntitlements
