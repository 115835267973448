import React from 'react'
import {  Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { OPEN_DIALOG } from '../redux/dialogReducer'
import { CLOSE_ALERT } from '../redux/alertReducer'
import AssignUserToAcsContainerHubEntitlementDialog from './dialog/assignUserToAcsContainerHubEntitlement'

const AssignUserToAcsContainerHubEntitlementButton = ({
  targetEntitlement,
  setNeedsFetchData,
}) => {
  const dispatch = useDispatch()

  const buttonLabel = 'Assign user to Entitlement'

  return <Button
      variant="contained"
      size="small"
      color="primary"
      onClick={() => {
        dispatch({
          type: OPEN_DIALOG,
          component: AssignUserToAcsContainerHubEntitlementDialog,
          props: {
            targetEntitlement,
            setNeedsFetchData,
          },
        })
        dispatch({
          type: CLOSE_ALERT,
        })
      }}
    >
      {buttonLabel}
    </Button>
}

export default AssignUserToAcsContainerHubEntitlementButton
