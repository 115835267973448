export const OPEN_DIALOG = 'OPEN_DIALOG'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

const dialogReducer = (
  state = { component: null, open: false, props: {} },
  action
) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return { component: action.component, props: action.props, open: true }
    case CLOSE_DIALOG:
      return { open: false, props: {}, component: null }
    default:
      return state
  }
}

export default dialogReducer
