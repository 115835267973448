import React, { useState } from 'react'
import MUIDataTable from 'mui-datatables'
import { TableCell, Link } from '@material-ui/core'
import { useParams } from 'react-router'
import Loader from 'react-loader-spinner'
import { Link as RouterLink } from 'react-router-dom'
import {
  formatDate,
  getEntitlementsByOrderItemSfid,
  getEntitlementsByProductCode,
} from '../../../utils'
import EmbeddedEntitlementTable from './EmbeddedEntitlementTable.jsx'
import { HeaderTableCell } from '../../containers.jsx'

export default function OrderItemTable({
  orderItems,
  entitlements,
  isOrderItemsLoading,
  setNeedsFetchData,
}) {
  const [rowsExpanded, setRowsExpanded] = useState([])

  const params = useParams()
  const columns = [
    {
      name: 'productCode',
      label: 'Product Code',
      options: {
        customHeadRender: () => (
          <HeaderTableCell key="Product Code">Product Code</HeaderTableCell>
        ),
        customBodyRenderLite: (dataIndex) => {
          const orderItemSfid = orderItems[dataIndex].orderItemSfid
          return (
            <Link
              component={RouterLink}
              to={`/orders/${params.orderSfid}/order-items/${orderItemSfid}`}
            >
              {orderItems[dataIndex].productCode}
            </Link>
          )
        },
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'productName',
      label: 'Product Name',
      options: {
        customHeadRender: () => (
          <HeaderTableCell key="Product Name">Product Name</HeaderTableCell>
        ),
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'unassigned',
      label: 'Unassigned',
      options: {
        customHeadRender: () => (
          <HeaderTableCell key="Unassigned">Unassigned</HeaderTableCell>
        ),
        customBodyRenderLite: (dataIndex) =>
          `${orderItems[dataIndex].remaining} / ${orderItems[dataIndex].quantity}`,
        filter: false,
        sortThirdClickReset: true,
      },
    },
    {
      name: 'deactivationDate',
      label: 'Deactivation Date',
      options: {
        customHeadRender: () => (
          <HeaderTableCell key="Deactivation Date">
            Deactivation Date
          </HeaderTableCell>
        ),
        filter: false,
        sortThirdClickReset: true,
        customBodyRenderLite: (dataIndex) =>
          formatDate(orderItems[dataIndex].deactivationDate),
      },
    },
  ]

  const options = {
    elevation: 4,
    expandableRows: true,
    expandableRowsHeader: false,
    print: false,
    filter: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: isOrderItemsLoading ? (
          <Loader type="ThreeDots" color="grey" />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const orderItemSfid = orderItems[rowMeta.dataIndex].orderItemSfid
      const innerEntitlements = getEntitlementsByOrderItemSfid(
        entitlements,
        orderItemSfid
      )
      const productCode = orderItems[rowMeta.dataIndex].productCode
      const entitlementsByProductCode = getEntitlementsByProductCode(
        entitlements,
        productCode
      )
      const showMax = 5
      return (
        <TableCell colSpan={rowData.length + 1}>
          <EmbeddedEntitlementTable
            entitlements={
              innerEntitlements && innerEntitlements.slice(0, showMax)
            }
            hasMore={innerEntitlements && innerEntitlements.length > showMax}
            productCode={orderItems[rowMeta.dataIndex].productCode}
            entitlementsOfOneProduct={entitlementsByProductCode}
            total={innerEntitlements ? innerEntitlements.length : 0}
            setNeedsFetchData={setNeedsFetchData}
            orderItemSfid={orderItems[rowMeta.dataIndex].orderItemSfid}
            isOrderItemsLoading={isOrderItemsLoading}
          />
        </TableCell>
      )
    },
    download: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15],
    rowsExpanded,
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
      setRowsExpanded(
        allRowsExpanded.map((rowExpanded) => rowExpanded.dataIndex)
      )
    },
  }

  return (
    <MUIDataTable
      title="Products"
      data={orderItems}
      columns={columns}
      options={options}
    />
  )
}
