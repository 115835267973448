export const OPEN_ALERT = 'OPEN_ALERT'
export const CLOSE_ALERT = 'CLOSE_ALERT'

const alertReducer = (
  state = { msg: '', severity: null, open: false, action: null },
  action
) => {
  switch (action.type) {
    case OPEN_ALERT:
      return {
        msg: action.msg,
        severity: action.severity,
        open: true,
        action: action.action,
      }
    case CLOSE_ALERT:
      return { msg: '', open: false }
    default:
      return state
  }
}

export default alertReducer
