import React from 'react'

const ConsentForEmailUseLegalText = () => (
  <>
    We are pleased that you wish to invite your colleague to the myAdvantest
    platform. Before you share the email address, please make sure to inform
    them about
    <br />• our Terms of Use{' '}
    <a
      href="https://my.advantest.com/static/terms.html"
      target="_blank"
      rel="noreferrer noopener"
    >
      here
    </a>{' '}
    and
    <br />• our Privacy Policy{' '}
    <a
      href="https://my.advantest.com/static/privacy.html"
      target="_blank"
      rel="noreferrer noopener"
    >
      here
    </a>
    .
  </>
)

export default ConsentForEmailUseLegalText
