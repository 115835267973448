import React from 'react'
import { IconButton, Tooltip, styled } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import CancelIcon from '@material-ui/icons/Cancel'
import Highlighter from 'react-highlight-words'
import { CLOSE_ALERT } from '../../../redux/alertReducer'
import { useDispatch } from 'react-redux'

const ContentContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
}))

const LeftContainer = styled('div')({
  display: 'flex',
  width: '80%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

const SimpleLineItem = styled('div')({
  marginBottom: 4,
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  overflow: 'hidden',
})

const TitleLineItem = styled('div')({
  marginBottom: 4,
  display: 'flex',
  width: '100%',
  overflow: 'hidden',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: 16,
})

const UserItem = ({
  user,
  ownershipType,
  deleteUser,
  delegateUser,
  searchWords,
  style,
}) => {
  const dispatch = useDispatch()
  return (
    <div style={style}>
      <ContentContainer>
        <LeftContainer>
          <TitleLineItem>
            {/* Name */}
            <Highlighter
              searchWords={searchWords}
              textToHighlight={`${user.lastName || '-'}, ${
                user.firstName || '-'
              }`}
            />
          </TitleLineItem>
          <SimpleLineItem>
            {/* Email */}
            <Highlighter
              searchWords={searchWords}
              textToHighlight={user.email}
            />
          </SimpleLineItem>
          <SimpleLineItem>
            {ownershipType === 'original-owner'
              ? 'Original owner'
              : ownershipType === 'delegated-owner'
              ? 'Delegated owner'
              : ''}
          </SimpleLineItem>
        </LeftContainer>

        {ownershipType !== 'original-owner' ? (
          ownershipType === 'delegated-owner' ? (
            <Tooltip title="remove ownership for user">
              <IconButton
                onClick={() => {
                  dispatch({ type: CLOSE_ALERT })
                  deleteUser(user.email)
                }}
                color="secondary"
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="delegate order to user">
              <IconButton
                onClick={() => {
                  dispatch({ type: CLOSE_ALERT })
                  delegateUser(user.email)
                }}
                color="primary"
              >
                <PersonAddIcon />
              </IconButton>
            </Tooltip>
          )
        ) : (
          <div />
        )}
      </ContentContainer>
    </div>
  )
}

export default UserItem
