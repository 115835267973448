import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert } from '@material-ui/lab'
import { useDispatch } from 'react-redux'
import { CLOSE_ALERT } from '../../../redux/alertReducer'

const CustomAlert = ({ message, severity, open, action }) => {
  const dispatch = useDispatch()

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch({ type: CLOSE_ALERT })
  }

  return (
    <Snackbar open={open} autoHideDuration={12000} onClose={handleClose}>
      <Alert
        severity={severity}
        variant="filled"
        onClose={handleClose}
        action={action}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default CustomAlert
