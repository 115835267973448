import React from 'react'
import Card from '@material-ui/core/Card'
import { CardContent, CardHeader } from '@material-ui/core'
import SimpleDetailItem from '../SimpleDetailItem'
import isEntitlementAcsContainerHub from '../../utils/isEntitlementAcsContainerHub'
import formatStorageQuota from '../../utils/formatStorageQuota'
import isEntitlementDojo from '../../utils/isEntitlementDojo'
import isEntitlementTECloud from '../../utils/isEntitlementTECloud'
import isEntitlementSmartInsight from '../../utils/isEntitlementSmartInsight'
import { formatDate } from '../../utils'

const EntitlementDetailsCard = ({ entitlement }) => {
  return (
    <Card>
      <CardHeader
        title={entitlement.entitlementId}
        titleTypographyProps={{ variant: 'h2' }}
      />
      <CardContent>
        <SimpleDetailItem label="Application" value={entitlement.application} />
        <SimpleDetailItem
          label="Assign Status"
          value={entitlement.assignStatus}
        />
        <SimpleDetailItem
          label="Product Name"
          value={entitlement.productName}
        />
        <SimpleDetailItem
          label="Product Code"
          value={entitlement.productCode}
        />
        <SimpleDetailItem
          label="Start Date"
          value={formatDate(entitlement.startDate)}
        />
        <SimpleDetailItem
          label="Expiry Date"
          value={formatDate(entitlement.expiryDate)}
        />
        {isEntitlementAcsContainerHub(entitlement) && (
          <>
            <SimpleDetailItem
              label="Organization Key"
              value={entitlement.organizationKey}
            />
            <SimpleDetailItem
              label="Storage Quota"
              value={formatStorageQuota(entitlement.storageQuota)}
            />
          </>
        )}
        {isEntitlementDojo(entitlement) && (
          <SimpleDetailItem
            label="Assigned Email"
            value={entitlement.assignedEmail}
          />
        )}
        {isEntitlementTECloud(entitlement) && (
          <>
            <SimpleDetailItem label="VM Number" value={entitlement.vmNumber} />
            <SimpleDetailItem label="Mode" value={entitlement.mode} />
          </>
        )}
        {isEntitlementSmartInsight(entitlement) && (
          <>
            <SimpleDetailItem label="VM Number" value={entitlement.vmNumber} />
            <SimpleDetailItem label="Mode" value={entitlement.mode} />
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default EntitlementDetailsCard
