import { useState, useEffect } from 'react'
import { ApiClient } from '../common'
import { useAuth0 } from '@auth0/auth0-react'

// using the old notated (non-v2) api for fetching a single entitlement
const useEntitlement = ({ entitlementId }) => {
  const { getAccessTokenSilently } = useAuth0()
  const [entitlement, setEntitlement] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [needsFetchData, setNeedsFetchData] = useState(true)

  useEffect(() => {
    if (needsFetchData) {
      const getEntitlement = async () => {
        setIsLoading(true)
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        })
        await ApiClient.get(`/entitlements/${entitlementId}`, token)
          .then((response) => {
            setEntitlement(response.data)
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false)
          })
      }
      getEntitlement()
      setNeedsFetchData(false)
    }
  }, [getAccessTokenSilently, needsFetchData, entitlementId])

  return { entitlement, isLoading, setNeedsFetchData }
}

export default useEntitlement
