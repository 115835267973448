import React from 'react'
import MUIDataTable from 'mui-datatables'
import Loader from 'react-loader-spinner'
import Box from '@material-ui/core/Box'
import { IconButton, Link, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { HeaderTableCell } from '../containers'
import ApiClient from '../../common/ApiClient'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { OPEN_ALERT } from '../../redux/alertReducer'

export default function EntitlementUsersTable({
  entitlementId,
  users,
  isLoading,
  setNeedsFetchData,
}) {
  const { getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()

  const unassign = (email) => async () => {
    const showError = () =>
      dispatch({
        type: OPEN_ALERT,
        msg: 'Error occurred and the user has not been unassigned.',
        severity: 'error',
        action: (
          <Link href="mailto:SMB-myAdvantest-Admin@advantest.com">
            <Button variant="contained" color="primary" size="small">
              Contact support
            </Button>
          </Link>
        ),
      })

    const token = await getAccessTokenSilently()
    await ApiClient.delete('/v2/assignment?application=ContainerHub', token, {
      entitlementId,
      email,
    })
      .then((response) => {
        if (response && response.status === 200) {
          setNeedsFetchData(true)
          dispatch({
            type: OPEN_ALERT,
            msg: `User (${email}) has been unassigned from the entitlement.`,
            severity: 'success',
          })
        } else {
          showError()
        }
      })
      .catch(() => {
        showError()
      })
  }

  const columns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        customHeadRender: () => (
          <HeaderTableCell key="email">Email</HeaderTableCell>
        ),
      },
    },
    {
      name: 'unassignUser',
      label: 'Unassign',
      options: {
        customHeadRender: () => (
          <HeaderTableCell key="unassignUser">Unassign</HeaderTableCell>
        ),
        customBodyRenderLite: (dataIndex) => (
          <IconButton
            onClick={unassign(users[dataIndex].email)}
            color="primary"
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
    },
  ]

  const options = {
    elevation: 4,
    download: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'vertical',
    print: false,
    filter: false,
    viewColumns: false,
    count: users.length,
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <Loader type="ThreeDots" color="grey" />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
  }

  return (
    <Box sx={{ marginTop: '8px' }}>
      <MUIDataTable
        style={{ marginTop: '8px' }}
        key="EntitlementUsers"
        title={'Users'}
        data={users}
        columns={columns}
        options={options}
      />
    </Box>
  )
}
