import { styled, TableCell, Typography } from '@material-ui/core'

// IE11 related
import { isIE11 } from '../'
import { getFooterHeight } from '../component/footer/Footer'
//

export const HEADER_HEIGHT = 64

export const ListItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: 650,
  paddingLeft: 0,
  paddingBottom: theme.spacing(2),
}))

export const DetailItemKey = styled('div')(({ theme }) => ({
  width: 150,
  color: theme.palette.black,
  fontSize: '16px',
}))

export const DetailItemValue = styled('div')(({ theme }) => ({
  width: 500,
  color: theme.palette.black,
  fontSize: '16px',
}))

export const ItemDetails = styled('div')(() => ({}))

export const PageContent = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
}))
export const BreadcrumbsContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',
  maxWidth: 2500,
}))

export const NoFooterContainer = styled('div')(() => {
  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    ...(isIE11
      ? {
          height: `calc(100% - ${getFooterHeight()}px)`,
          minHeight: `calc(100vh - ${getFooterHeight()}px)`,
        }
      : {}),
  }
})

export const DetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: theme.spacing(2),
}))

export const HeaderTableCell = styled(TableCell)({
  fontWeight: 'bold',
})

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.black,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}))

export const CenterContainer = styled('div')({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})
