import React from 'react'
import Header from '../header/Header'
import OrderTable from './OrderTable.jsx'
import {
  PageContent,
  NoFooterContainer,
  BreadcrumbsContainer,
  CenterContainer,
} from '../containers.jsx'
import { CustomBreadcrumbs } from '../../common'
import Footer from '../footer/Footer'
import { styled } from '@material-ui/core'

const TableContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export default function Orders() {
  return (
    <PageContent>
      <NoFooterContainer>
        <Header />
        <CenterContainer>
          <BreadcrumbsContainer>
            <CustomBreadcrumbs
              items={[
                { label: 'Home', path: '/' },
                { label: 'Orders', path: '/orders' },
              ]}
            />
            <TableContainer>
              <OrderTable initialPageSize={10} />
            </TableContainer>
          </BreadcrumbsContainer>
        </CenterContainer>
      </NoFooterContainer>
      <Footer />
    </PageContent>
  )
}
