//orderItemSfidを受け取って，そのorderItemSfidに
// 紐づくEntitlementを取得する関数
export default function (entitlements, productCode) {
  if (entitlements === undefined || productCode === undefined) {
    return
  }

  const foundEntitlements = entitlements.filter(
    (entitelment) => entitelment.productCode === productCode
  )
  return foundEntitlements
}
