import { Card, IconButton, styled, SvgIcon, useTheme } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { IconConst } from '../../common/icons'
import unassignedEntitlementsWillExpireSoon from '../../utils/unassignedEntitlementsWillExpireSoon'

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none !important',
  cursor: 'pointer',
})

export const shopCategoryToEMSAppTypeMap = {
  TECloud: 'ACS%20TE-Cloud',
  OnlineTraining: 'OnlineTrainingTheDojo',
}

const IMG_HEIGHT = 180
const CARD_WIDTH = 416

const ProductCard = ({
  orderItem,
  nonHistoryEntitlementsOfThisProduct,
  media,
}) => {
  const theme = useTheme()

  return (
    <Card
      key={orderItem.productCode}
      style={{
        borderRadius: 0,
        height: IMG_HEIGHT,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <div
        style={{
          width: CARD_WIDTH - IMG_HEIGHT,
          padding: 16,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <StyledLink to={`/entitlements?product=${orderItem.productCode}`}>
          <strong>{orderItem.productName}</strong>
        </StyledLink>
        <div>{orderItem.productCode}</div>
        <div>
          {`${nonHistoryEntitlementsOfThisProduct.length} entitlements`}
        </div>
        <div>
          {`${
            nonHistoryEntitlementsOfThisProduct.filter(
              (entitlement) => entitlement.assignStatus === 'Unassigned'
            ).length
          } unassigned entitlements`}
        </div>
        <div>
          {`${
            nonHistoryEntitlementsOfThisProduct.filter(
              (entitlement) => entitlement.assignStatus === 'Deactivated'
            ).length
          } deactivated entitlements`}
        </div>
        <div>
          {`${
            nonHistoryEntitlementsOfThisProduct.filter(
              unassignedEntitlementsWillExpireSoon
            ).length
          } expire soon entitlements`}
        </div>
        <div>
          {nonHistoryEntitlementsOfThisProduct[0] && (
            <IconButton
              onClick={() => {
                window.location.href = `${
                  process.env.REACT_APP_MYADVANTEST_SHOP_URL
                }/${
                  shopCategoryToEMSAppTypeMap[
                    nonHistoryEntitlementsOfThisProduct[0].application
                  ]
                }/${orderItem.productCode}`
              }}
            >
              <SvgIcon>
                <path d={IconConst.Cart.d} />
              </SvgIcon>
            </IconButton>
          )}
        </div>
      </div>
      <div
        style={{
          width: IMG_HEIGHT,
          heigth: IMG_HEIGHT,
        }}
      >
        <img
          style={{
            width: IMG_HEIGHT,
            heigth: IMG_HEIGHT,
          }}
          src={media}
          alt={orderItem.productCode}
        />
      </div>
    </Card>
  )
}

export default ProductCard
