//orderItemSfidを受け取って，そのorderItemSfidに
// 紐づくEntitlementを取得する関数
export default function (entitlements, orderItemSfid) {
  if (entitlements === undefined || orderItemSfid === undefined) {
    return
  }

  const foundEntitlements = entitlements.filter(
    (entitelment) => entitelment.orderItemSfid === orderItemSfid
  )
  return foundEntitlements
}
