import React, { useState } from 'react'
import {
  styled,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import UserItem from './UserItem'
import { FixedSizeList } from 'react-window'
import useUsers from '../../../hooks/useUsers'

const ContentContainer = styled('div')({
  padding: '10px 0px',
})

const LIST_HEIGHT = 660

const ProgressContainer = styled('div')({
  width: '100%',
  height: LIST_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export default function UserWithAssignStatusTable({
  // The procedure to execute User Assign API
  assign,
  assignedUsers,
  pendingUsers,
  inProgressUsers,
  completedUsers,
  expiredUsers,
  deactivatedUsers,
}) {
  const [clicked, setClicked] = useState(false)
  const { users, isLoading, setQuery, query, count } = useUsers({
    initialPageSize: 50,
  })

  const renderRow = ({ index, style }) => (
    <UserItem
      key={users[index].email}
      user={users[index]}
      style={style}
      searchWords={[query]}
      showAssignButton={
        !(
          assignedUsers.includes(users[index].email) ||
          pendingUsers.includes(users[index].email) ||
          inProgressUsers.includes(users[index].email) ||
          completedUsers.includes(users[index].email) ||
          expiredUsers.includes(users[index].email) ||
          deactivatedUsers.includes(users[index].email)
        )
      }
      assign={(email) =>
        assign(
          email,
          () => setClicked(true),
          () => setClicked(false),
          () => setClicked(false)
        )
      }
      statusLabel={
        pendingUsers.includes(users[index].email)
          ? 'Pending'
          : assignedUsers.includes(users[index].email)
          ? 'Assigned'
          : inProgressUsers.includes(users[index].email)
          ? 'In Progress'
          : completedUsers.includes(users[index].email)
          ? 'Completed'
          : expiredUsers.includes(users[index].email)
          ? 'Expired'
          : deactivatedUsers.includes(users[index].email)
          ? 'Deactivated'
          : 'Unassigned'
      }
    />
  )

  return (
    <ContentContainer>
      <TextField
        style={{
          margin: '28px 16px 34px 0px',
          width: 485,
        }}
        autoFocus
        placeholder="Search by email, first name or last name..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        helperText={isLoading ? '' : `${count} matching users found.`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {isLoading || clicked ? (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      ) : (
        <FixedSizeList
          height={LIST_HEIGHT}
          itemCount={users.length}
          itemSize={80}
          width={504}
          style={{ marginBottom: '20px' }}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </ContentContainer>
  )
}
