import React, { useEffect } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import {
  PageContent,
  NoFooterContainer,
  BreadcrumbsContainer,
  CenterContainer,
} from '../containers.jsx'
import CustomBreadcrumbs from '../Breadcrumbs/CustomBreadcrumbs'
import EntitlementTable from './EntitlementTable'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { styled } from '@material-ui/core'
import uniqBy from 'lodash/uniqBy'
import { useHistory, useLocation } from 'react-router'
import ProductSelector from './ProductSelector'
import entitlementIsHistory from '../../utils/entitlementIsHistory'
import getDefaultProductCode from '../../utils/getDefaultProductCode'
import getEntitlementsByProductCode from '../../utils/getEntitlementsByProductCode'
import sortEntitlements from '../../utils/sortEntitlements.js'
import useOrderItems from '../../hooks/useOrderItems.js'
import useEntitlements from '../../hooks/useEntitlements.js'

export const getProductCodeFromSearch = (search) =>
  search.includes('?product=') ? search.split('?product=')[1] || null : null

const HorizontalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
})

const Entitlements = () => {
  const history = useHistory()

  const { orderItems, isLoading: isOrderItemsLoading } = useOrderItems({})
  const {
    entitlements,
    isLoading: isEntitlementLoading,
    setNeedsFetchData,
  } = useEntitlements({})

  const location = useLocation()

  const tableContent = uniqBy(
    orderItems,
    (orderItem) => orderItem.productCode
  ).reduce((tableContent, orderItem) => {
    const entitlementsOfThisProduct = entitlements
      ? entitlements.filter(
          (entitlement) => entitlement.productCode === orderItem.productCode
        )
      : []
    const historyEntitlementsOfThisProduct = entitlementsOfThisProduct
      ? entitlementsOfThisProduct.filter(entitlementIsHistory)
      : []
    const activeEntitlementsOfThisProduct = entitlementsOfThisProduct
      ? entitlementsOfThisProduct.filter(
          (entitlement) => !entitlementIsHistory(entitlement)
        )
      : []

    return {
      ...tableContent,
      [orderItem.productCode]: {
        history: historyEntitlementsOfThisProduct,
        active: activeEntitlementsOfThisProduct,
      },
    }
  }, {})

  useEffect(() => {
    const defaultProduct = getDefaultProductCode(orderItems, entitlements)

    if (
      location.pathname === '/entitlements' &&
      !location.search &&
      defaultProduct
    ) {
      history.push(`/entitlements?product=${defaultProduct}`)
    }
  }, [
    location.pathname,
    location.search,
    tableContent,
    entitlements,
    history,
    orderItems,
  ])

  const productCodeFromSearch = getProductCodeFromSearch(location.search)
  const historyEntitlements =
    productCodeFromSearch && tableContent && tableContent[productCodeFromSearch]
      ? tableContent[productCodeFromSearch].history
      : []

  const activeEntitlements =
    productCodeFromSearch && tableContent && tableContent[productCodeFromSearch]
      ? tableContent[productCodeFromSearch].active
      : []

  return (
    <PageContent>
      <NoFooterContainer>
        <Header />
        <CenterContainer>
          <BreadcrumbsContainer>
            <CustomBreadcrumbs
              items={[
                { label: 'Home', path: '/' },
                {
                  label: 'Entitlements',
                  path: `/entitlements?product=${getDefaultProductCode(
                    orderItems,
                    entitlements
                  )}`,
                },
              ]}
            />
            <div style={{ height: 16 }} />
            <HorizontalContainer>
              <ProductSelector
                onSelect={(productCode) =>
                  history.push(`/entitlements?product=${productCode}`)
                }
                tableContent={tableContent}
                selectedProductCode={productCodeFromSearch}
                orderItems={uniqBy(
                  orderItems,
                  (orderItem) => orderItem.productCode
                )}
                isLoading={isOrderItemsLoading}
              />

              <div style={{ width: '70vw' }}>
                <EntitlementTable
                  title="Active Entitlements"
                  // ensure rerender of table when selecting another product, in order to reset pagination for example
                  key={
                    (activeEntitlements &&
                      activeEntitlements[0] &&
                      activeEntitlements[0].entitlementId) ||
                    Math.random()
                  }
                  entitlements={sortEntitlements(activeEntitlements)}
                  isEntitlementLoading={isEntitlementLoading}
                  setNeedsFetchData={setNeedsFetchData}
                  entitlementsByProductCode={getEntitlementsByProductCode(
                    activeEntitlements,
                    productCodeFromSearch
                  )}
                />
                <div style={{ height: 32 }} />
                <EntitlementTable
                  title="History Entitlements"
                  // ensure rerender of table when selecting another product, in order to reset pagination for example
                  key={
                    (historyEntitlements &&
                      historyEntitlements[0] &&
                      historyEntitlements[0].entitlementId) ||
                    Math.random()
                  }
                  entitlements={sortEntitlements(historyEntitlements)}
                  isEntitlementLoading={isEntitlementLoading}
                  setNeedsFetchData={setNeedsFetchData}
                  entitlementsByProductCode={getEntitlementsByProductCode(
                    historyEntitlements,
                    productCodeFromSearch
                  )}
                />
              </div>
            </HorizontalContainer>
          </BreadcrumbsContainer>
        </CenterContainer>
      </NoFooterContainer>
      <Footer />
    </PageContent>
  )
}

export default withAuthenticationRequired(Entitlements)
