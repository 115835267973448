import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import {
  ListItemContainer,
  DetailItemKey,
  DetailItemValue,
  ItemDetails,
  PageContent,
  DetailsContainer,
  PageTitle,
  NoFooterContainer,
  BreadcrumbsContainer,
  CenterContainer,
} from '../containers.jsx'
import CustomBreadcrumbs from '../Breadcrumbs/CustomBreadcrumbs'
import EntitlementTable from '../entitlements/EntitlementTable'
import getEntitlementsByOrderItemSfid from '../../utils/getEntitlementsByOrderItemSfid'
import ApiClient from '../../common/ApiClient.js'
import { getEntitlementsByProductCode, getOrderItemBySfid } from '../../utils'
import groupOrderItemsIntoOrders from '../../utils/groupOrderItemsIntoOrders.js'
import Header from '../header/Header'
import { formatDate } from '../../utils/formatDate'
import Footer from '../footer/Footer'

const OrderItemDetail = () => {
  const { getAccessTokenSilently } = useAuth0()
  const params = useParams()
  const [orderItem, setOrderItem] = useState({})
  const [isEntitlementLoading, setIsEntitlementLoading] = useState(true)
  //バックエンドからデータを再取得するかのフラグ
  //needsFetchDataに変更が加えられるとメソッドが実行される
  //API実行の成功時にフラグを切り替えるために，ダイアログコンポーネントに
  //setNeedsFetchDataを渡す必要がある
  const [needsFetchData, setNeedsFetchData] = useState(true)
  const [entitlements, setEntitlements] = useState([])
  const [entitlementsByProductCode, setEntitlementsByProductCode] = useState([])

  useEffect(() => {
    const getEntitlements = async () => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      })
      await ApiClient.get('/entitlements', token)
        .then((response) => {
          const fetchedEntitlements = response.data.entitlements
          const entitlementsByOrderItemSfid = getEntitlementsByOrderItemSfid(
            fetchedEntitlements,
            params.orderItemSfid
          )
          setEntitlements(entitlementsByOrderItemSfid)
          setEntitlementsByProductCode(
            getEntitlementsByProductCode(
              fetchedEntitlements,
              entitlementsByOrderItemSfid[0].productCode
            )
          )
        })
        .catch(() => {})

        .finally(() => {
          setIsEntitlementLoading(false)
          setNeedsFetchData(false)
        })
    }
    getEntitlements()
  }, [getAccessTokenSilently, params.orderItemSfid, needsFetchData])

  // Cannot use the Data Hook here yet, because we would need a GET /orderItems/{id} endpoint
  useEffect(() => {
    const getOrders = async () => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      })
      await ApiClient.get(`/orders`, token)
        .then((response) => {
          const uniqueOrders = groupOrderItemsIntoOrders(response.data.orders)
          const orderSfid = uniqueOrders.filter(
            (uniqueOrder) => uniqueOrder.orderSfid === params.orderSfid
          )[0].orderSfid

          const orderItems = getOrderItemBySfid(response.data.orders, orderSfid)

          const foundOrderItem = orderItems.find(
            (orderItem) => orderItem.orderItemSfid === params.orderItemSfid
          )
          setOrderItem(foundOrderItem)
        })
        .catch(() => {})
        .finally(() => {
          setNeedsFetchData(false)
        })
    }
    getOrders()
  }, [
    getAccessTokenSilently,
    params.orderSfid,
    params.orderItemSfid,
    needsFetchData,
  ])

  return (
    <PageContent>
      <NoFooterContainer>
        <Header />
        <CenterContainer>
          <BreadcrumbsContainer>
            <CustomBreadcrumbs
              items={[
                { label: 'Home', path: '/' },
                { label: 'Orders', path: '/orders' },
                {
                  label: orderItem ? orderItem.orderNumber : '...',
                  path: `/orders/${params.orderSfid}`,
                },
                {
                  label: `${
                    orderItem && orderItem.productCode
                      ? orderItem.productCode
                      : '...'
                  } (${
                    orderItem && orderItem.productName
                      ? orderItem.productName
                      : '...'
                  })`,
                  path: `/orders/${params.orderSfid}/order-items/${params.orderItemSfid}`,
                },
              ]}
            />
            <DetailsContainer>
              <ItemDetails>
                <PageTitle>Order Details</PageTitle>
                <ListItemContainer>
                  <DetailItemKey>Order Number:</DetailItemKey>
                  {orderItem && (
                    <DetailItemValue>{orderItem.orderNumber}</DetailItemValue>
                  )}
                </ListItemContainer>
                <ListItemContainer>
                  <DetailItemKey>Order Date:</DetailItemKey>
                  {orderItem && (
                    <DetailItemValue>
                      {formatDate(orderItem.orderDate)}
                    </DetailItemValue>
                  )}
                </ListItemContainer>
                <PageTitle>Product Details</PageTitle>
                <ListItemContainer>
                  <DetailItemKey>Product Code:</DetailItemKey>
                  {orderItem && (
                    <DetailItemValue>{orderItem.productCode}</DetailItemValue>
                  )}
                </ListItemContainer>
                <ListItemContainer>
                  <DetailItemKey>Product Name:</DetailItemKey>
                  {orderItem && (
                    <DetailItemValue>{orderItem.productName}</DetailItemValue>
                  )}
                </ListItemContainer>
              </ItemDetails>
            </DetailsContainer>
            <EntitlementTable
              entitlements={entitlements}
              isEntitlementLoading={isEntitlementLoading}
              setNeedsFetchData={setNeedsFetchData}
              entitlementsByProductCode={entitlementsByProductCode}
            />
          </BreadcrumbsContainer>
        </CenterContainer>
      </NoFooterContainer>
      <Footer />
    </PageContent>
  )
}

export default OrderItemDetail
