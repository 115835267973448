import React, { useState } from 'react'
import {
  styled,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { FixedSizeList } from 'react-window'
import UserItem from '../assignUserToDojoEntitlement/UserItem'
import useUsers from '../../../hooks/useUsers'
import { useAuth0 } from '@auth0/auth0-react'
import ApiClient from '../../../common/ApiClient'
import { useDispatch } from 'react-redux'
import { OPEN_ALERT } from '../../../redux/alertReducer.jsx'

const ContentContainer = styled('div')({
  padding: '10px 0px',
})

const LIST_HEIGHT = 660

const ProgressContainer = styled('div')({
  width: '100%',
  height: LIST_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export default function AssignACSUsersList({
  targetEntitlement,
  handleClose,
  assignAsAdmin = false,
  setNeedsFetchData,
}) {
  const [clicked, setClicked] = useState(false)

  const { users, isLoading, setQuery, query, count } = useUsers({
    initialPageSize: 50,
  })
  const dispatch = useDispatch()

  const { getAccessTokenSilently } = useAuth0()

  const assign = async (
    email,
    onStartProcess = () => {},
    onSuccess = () => {},
    onFailure = () => {}
  ) => {
    const onError = () => {
      dispatch({
        type: OPEN_ALERT,
        msg: 'Error occurred. Please contact SMB-myAdvantest-Admin@advantest.com for assistance.',
        severity: 'error',
        action: (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(
                'SMB-myAdvantest-Admin@advantest.com'
              )
            }}
          >
            Copy email
          </Button>
        ),
      })
      onFailure()
    }

    const request = {
      entitlementId: targetEntitlement.entitlementId,
      email,
      isAdmin: assignAsAdmin,
    }
    onStartProcess()
    // Execute API
    const token = await getAccessTokenSilently()
    await ApiClient.post(
      '/v2/assignment?application=ContainerHub',
      token,
      request
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: OPEN_ALERT,
            msg: `Entitlement ${
              targetEntitlement && targetEntitlement.entitlementId
                ? targetEntitlement.entitlementId
                : ''
            } has been assigned to ${email}.`,
            severity: 'success',
          })
          handleClose()
          setNeedsFetchData(true)
          onSuccess()
        } else {
          onError()
        }
      })
      .catch(() => {
        onError()
      })
  }

  const renderRow = ({ index, style }) => (
    <UserItem
      key={users[index].email}
      user={users[index]}
      style={style}
      searchWords={[query]}
      showAssignButton={true}
      assign={(email) =>
        assign(
          email,
          () => setClicked(true),
          () => setClicked(false),
          () => setClicked(false)
        )
      }
      statusLabel={''}
    />
  )

  return (
    <ContentContainer>
      <TextField
        style={{
          margin: '28px 16px 34px 0px',
          width: 485,
        }}
        autoFocus
        placeholder="Search by email, first name or last name..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        helperText={isLoading ? '' : `${count} matching users found.`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {isLoading || clicked ? (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      ) : (
        <FixedSizeList
          height={LIST_HEIGHT}
          itemCount={users.length}
          itemSize={80}
          width={504}
          style={{ marginBottom: '20px' }}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </ContentContainer>
  )
}
