import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import OrderItemTable from './orderItemTable'
import { Button } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'
import { ApiClient, CustomBreadcrumbs } from '../../common'
import { getOrderItemBySfid, groupOrderItemsIntoOrders } from '../../utils'
import Header from '../header/Header'
import { formatDate } from '../../utils/formatDate'
import {
  ListItemContainer,
  DetailItemKey,
  DetailItemValue,
  ItemDetails,
  PageContent,
  DetailsContainer,
  PageTitle,
  BreadcrumbsContainer,
  NoFooterContainer,
  CenterContainer,
} from '../containers.jsx'
import { useDispatch } from 'react-redux'
import Footer from '../footer/Footer'
import { OPEN_DIALOG } from '../../redux/dialogReducer'
import { CLOSE_ALERT } from '../../redux/alertReducer'
import DelegateDialog from '../dialog/delegateDialog'

const OrderDetail = () => {
  const { getAccessTokenSilently } = useAuth0()
  const params = useParams()
  const [orderItems, setOrderItems] = useState([])
  const [entitlements, setEntitlements] = useState([])
  const [delegatedOrder, setDelegatedOrder] = useState({})
  const [isOrderItemsLoading, setIsOrderItemsLoading] = useState(true)
  //バックエンドからデータを再取得するかのフラグ
  //needsFetchDataに変更が加えられるとメソッドが実行される
  //API実行の成功時にフラグを切り替えるために，ダイアログコンポーネントに
  //setNeedsFetchDataを渡す必要がある
  const [needsFetchData, setNeedsFetchData] = useState(true)

  const getEntitlements = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    })
    await ApiClient.get('/entitlements', token)
      .then((response) => {
        setEntitlements(response.data.entitlements)
      })
      .catch(() => {})
      .finally(() => {
        setNeedsFetchData(false)
      })
  }, [getAccessTokenSilently])

  // ログインユーザが所有者または委任所有者であるOrder
  // をバックエンドから取得する
  const getOrders = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    })
    setIsOrderItemsLoading(true)
    await ApiClient.get(`/orders`, token)
      .then((response) => {
        const uniqueOrders = groupOrderItemsIntoOrders(response.data.orders)
        const orderSfid = uniqueOrders.filter(
          (uniqueOrder) => uniqueOrder.orderSfid === params.orderSfid
        )[0].orderSfid
        setOrderItems(getOrderItemBySfid(response.data.orders, orderSfid))

        setDelegatedOrder(
          response.data.orders.filter(
            (order) => order.orderSfid === params.orderSfid
          )[0]
        )
      })
      .catch(() => {
        setIsOrderItemsLoading(false)
      })
      .finally(() => {
        setIsOrderItemsLoading(false)
        setNeedsFetchData(false)
      })
  }, [getAccessTokenSilently, params.orderSfid])

  useEffect(() => {
    if (needsFetchData) {
      getOrders()
      getEntitlements()
    }
  }, [needsFetchData, getOrders, getEntitlements])

  const dispatch = useDispatch()

  return (
    <>
      <PageContent>
        <NoFooterContainer>
          <Header />
          <CenterContainer>
            <BreadcrumbsContainer>
              <CustomBreadcrumbs
                items={[
                  { label: 'Home', path: '/' },
                  { label: 'Orders', path: '/orders' },
                  {
                    label: orderItems[0] ? orderItems[0].orderNumber : '...',
                    path: `/orders/${params.orderSfid}`,
                  },
                ]}
              />
              <DetailsContainer>
                <ItemDetails>
                  <PageTitle>Order Details</PageTitle>
                  <ListItemContainer>
                    <DetailItemKey>Order Number:</DetailItemKey>
                    {orderItems[0] && (
                      <DetailItemValue>
                        {orderItems[0].orderNumber}
                      </DetailItemValue>
                    )}
                  </ListItemContainer>
                  <ListItemContainer>
                    <DetailItemKey>Order Date:</DetailItemKey>
                    {orderItems[0] && (
                      <DetailItemValue>
                        {formatDate(orderItems[0].orderDate)}
                      </DetailItemValue>
                    )}
                  </ListItemContainer>
                </ItemDetails>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: OPEN_DIALOG,
                      component: DelegateDialog,
                      props: {
                        delegatedOrder,
                      },
                    })
                    dispatch({
                      type: CLOSE_ALERT,
                    })
                  }}
                >
                  Order delegation
                </Button>
              </DetailsContainer>
              <OrderItemTable
                orderItems={orderItems}
                entitlements={entitlements}
                isOrderItemsLoading={isOrderItemsLoading}
                setNeedsFetchData={setNeedsFetchData}
              />
            </BreadcrumbsContainer>
          </CenterContainer>
        </NoFooterContainer>
        <Footer />
      </PageContent>
    </>
  )
}

export default OrderDetail
